<template>
  <div class="column full-height">
    <div class="row full-height">
      <div class="col-8 main-content">
        <div>
          <runai-svg-icon name="runai" class="logo-icon"></runai-svg-icon>
        </div>
        <div class="title">Create your first cluster on AWS and start running ML workloads with Run:ai</div>
        <div class="subtitle">To create an AI cluster, you'll need:</div>
        <div class="requirements-list">
          <q-list padding class="list-checkmark-icons">
            <q-item>
              <q-item-section thumbnail>
                <q-icon class="list-icon" name="fa-solid fa-circle-check" />
              </q-item-section>
              <q-item-section>A working AWS account</q-item-section>
            </q-item>
            <q-item>
              <q-item-section thumbnail>
                <q-icon class="list-icon" name="fa-solid fa-circle-check" />
              </q-item-section>
              <q-item-section
                ><span>These <a href="javascript:void(0)" class="permissions-link">permissions</a></span></q-item-section
              >
            </q-item>
          </q-list>
          <div class="subtitle">Once you've confirmed all of that:</div>
          <q-list class="list-checkmark-icons">
            <q-item>
              <q-item-section thumbnail>
                <q-icon class="list-icon" name="fa-solid fa-circle-1" />
              </q-item-section>
              <q-item-section>Click SET UP CLUSTER, which will take you to the AWS console</q-item-section>
            </q-item>
            <q-item>
              <q-item-section thumbnail>
                <q-icon class="list-icon" name="fa-solid fa-circle-2" />
              </q-item-section>
              <q-item-section>Follow the steps there to complete the EKS cluster installation</q-item-section>
            </q-item>
            <q-item>
              <q-item-section thumbnail>
                <q-icon class="list-icon" name="fa-solid fa-circle-3" />
              </q-item-section>
              <q-item-section>Then come back to this tab to launch Run:ai</q-item-section>
            </q-item>
          </q-list>
        </div>
        <div>
          <q-btn class="setup-button q-mt-lg" label="SETUP CLUSTER" color="primary" @click="openSetup" />
        </div>
        <div class="footer">
          Check out the video or the <a class="guide-link" href="javascript:void(0)">Set up cluster guide</a> for more
          information
        </div>
      </div>
      <div class="col-4 loading">
        <div class="profile"><profile-dropdown dark-mode /></div>
        <div class="q-pa-md q-mx-auto connected-title">
          <div class="q-pa-md q-mx-auto loading-message">
            <div>Waiting for cluster to connect</div>
          </div>
          <div class="q-pt-xl connected-icon" v-if="shouldShowLoading">
            <vue3-lottie :animation-data="clusterConnect1" :height="189" :width="256" :speed="1.0" />
          </div>
          <div class="q-pt-xl connected-icon" v-if="!shouldShowLoading">
            <vue3-lottie :animation-data="clusterConnect2" :height="189" :width="256" :speed="1.0" :loop="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
// stores
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";
// enums
import { TRIAL_NAMES } from "@/router/trial.routes/trial.routes.names";
// utils
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { intervalUtil } from "@/utils/interval.util";
import { EIntervalLabels } from "@/models/interval.model";
// models

// animation
import { Vue3Lottie } from "vue3-lottie";
import clusterConnect1 from "@/assets/icons/animation/cluster/clusterConnect1.json";
import clusterConnect2 from "@/assets/icons/animation/cluster/clusterConnect2.json";
import ProfileDropdown from "@/components/infra/top-header/profile-dropdown/profile-dropdown.vue";
import { ClusterDisplayedStatusStateEnum, type DisplayedCluster } from "@/swagger-models/cluster-service-client";

const SHOW_CONNECTED_TIMEOUT = 2500;
const TRIAL_CLUSTER_NAME = "trial-cluster";
export default defineComponent({
  components: { ProfileDropdown, RunaiSvgIcon, Vue3Lottie },
  data() {
    return {
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      authStore: useAuthStore(),
      clusterCheckerIntervalId: 0 as number,
      clusterConnect1: clusterConnect1 as object,
      clusterConnect2: clusterConnect2 as object,
      shouldShowLoading: true,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },

  mounted() {
    this.fetchClustersInterval();
    this.checkClusterForConnectedStatus();
  },
  computed: {
    tenantName(): string {
      return this.authStore.tenant.name;
    },
  },
  methods: {
    checkClusterForConnectedStatus(): void {
      intervalUtil.startInterval(EIntervalLabels.CheckClusterStatus, () => {
        this.redirectOnStatusConnected();
      });
    },
    async redirectOnStatusConnected() {
      if (
        this.clusterStore.clusters.length > 0 &&
        this.clusterStore.clusters[0].status?.state === ClusterDisplayedStatusStateEnum.Connected
      ) {
        this.shouldShowLoading = false;
        setTimeout(() => {
          this.stopIntervals();
          this.$router.push({ name: TRIAL_NAMES.CLUSTER_CONNECTED });
        }, SHOW_CONNECTED_TIMEOUT);
      }
    },

    async fetchClustersInterval(): Promise<void> {
      intervalUtil.startInterval(EIntervalLabels.FetchClusters, async () => {
        await this.clusterStore.loadClusters();
      });
    },

    async openSetup() {
      if (this.clusterStore.clusters.length === 0) {
        const cluster = await this.createTrialCluster();
        if (this.isClusterValid(cluster)) {
          const installationUrl = await clusterService.getAwsClusterInstallationUrl(cluster.uuid);
          if (installationUrl != "") {
            window.open(installationUrl, "_blank");
          } else {
            console.log("error getting installation url");
          }
        } else {
          console.log("cluster creation failed");
        }
      }
    },
    async createTrialCluster(): Promise<DisplayedCluster> {
      return await clusterService.create(TRIAL_CLUSTER_NAME);
    },

    isClusterValid(cluster: DisplayedCluster): boolean {
      return cluster && cluster.uuid !== "";
    },
    stopIntervals() {
      intervalUtil.stopInterval(EIntervalLabels.CheckClusterStatus);
      intervalUtil.stopInterval(EIntervalLabels.FetchClusters);
    },
  },
  unmounted() {
    this.stopIntervals();
  },
});
</script>
<style lang="scss" scoped>
.main-content {
  background-color: $navy;
  padding: 45px 0 0 45px;
}

.full-height {
  flex: 1;
}

.logo-icon {
  width: 60px;
  height: 40px;
}

.title {
  font-size: 36px;
  font-weight: 500;
  color: $white;
  margin-top: 40px;
  line-height: 50px;
}

.subtitle {
  font-size: 16px;
  font-weight: 700;
  color: $white;
  margin-top: 50px;
  line-height: 26px;
}

.requirements-list {
  font-size: 16px;
  font-weight: 700;
  color: $white;
}

.list-checkmark-icons {
  font-size: 16px;
  font-weight: 400;
}

.list-icon {
  font-size: 16px !important;
  color: $white-60;
}

.permissions-link {
  color: $white;
  text-decoration: underline;
}

.setup-button {
  height: 36px;
  border-radius: 3px;
}

.footer {
  color: $white-60;
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  margin-top: 100px;
}

.guide-link {
  color: $white;
  text-decoration: underline;
}

.loading-message {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}

.loading {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile {
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px;
}
</style>
