<template>
  <section class="workload-form-wrapper">
    <runai-form-wrapper :form-state="formState" ref="workloadFormWrapper" @leave-page="$emit('leave-page', $event)">
      <slot />
    </runai-form-wrapper>

    <section class="workload-form-actions-actions-container row q-mt-md">
      <div class="error-messages-container row flex-1">
        <q-field class="col-4 form-hint flex-1" :model-value="displayFormHint" :rules="[isFormIncomplete]"></q-field>
      </div>

      <div class="navigation-btns">
        <q-btn flat color="primary" :label="cancelBtnText" class="q-mr-sm" @click="$emit('canceled')" aid="cancel-btn" />
        <q-btn color="primary" :label="submitBtnText" aid="submit-btn" :loading="submitting" @click="beforeSubmitting" />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";

// constants
import { errorMessages } from "@/common/error-message.constant";
import { LEAVE_REASON } from "@/components/common/runai-form-wrapper";

export default defineComponent({
  components: {
    RunaiFormWrapper,
  },
  emits: ["canceled", "submit", "back-clicked", "leave-page"],
  props: {
    formState: {
      type: Object as PropType<object>,
      required: true,
    },
    submitBtnText: {
      type: String as PropType<string>,
      required: true,
    },
    cancelBtnText: {
      type: String as PropType<string>,
      required: true,
    },
    submitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
    };
  },
  methods: {
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : errorMessages.FORM_INCOMPLETE;
    },
    async validate(): Promise<boolean> {
      const workloadFormWrapper: HTMLFormElement | unknown = this.$refs["workloadFormWrapper"];
      if (!workloadFormWrapper) return false;

      return await (workloadFormWrapper as HTMLFormElement).validate();
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    async beforeSubmitting(): Promise<void> {
      const success = await this.validate();
      if (!success) {
        this.showHint();
        return;
      }

      this.$emit("submit");
    },
    onBack(): void {
      (this.$refs.workloadFormWrapper as HTMLFormElement).beforeLeave();
    },
    onLeavePage(leaveReason: LEAVE_REASON): void {
      if (leaveReason === LEAVE_REASON.LEAVE_PAGE) {
        this.$emit("back-clicked");
      }
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
<style lang="scss" scoped>
.workload-form-wrapper {
  .workload-form-actions-actions-container {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
}
</style>
