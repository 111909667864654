/* tslint:disable */
/* eslint-disable */
/**
 * Metrics
 * An API for querying metrics
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface CountReports200Response
 */
export interface CountReports200Response {
    /**
     * 
     * @type {number}
     * @memberof CountReports200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface GetMetrics200Response
 */
export interface GetMetrics200Response {
    /**
     * 
     * @type {string}
     * @memberof GetMetrics200Response
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetrics200Response
     */
    'query': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMetrics200Response
     */
    'isRange': boolean;
    /**
     * 
     * @type {Array<Result>}
     * @memberof GetMetrics200Response
     */
    'data': Array<Result>;
}
/**
 * 
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface ListReports200Response
 */
export interface ListReports200Response {
    /**
     * 
     * @type {Array<Report>}
     * @memberof ListReports200Response
     */
    'reports'?: Array<Report>;
}
/**
 * 
 * @export
 * @interface Measurement
 */
export interface Measurement {
    /**
     * 
     * @type {string}
     * @memberof Measurement
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Measurement
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MetricInformation
 */
export interface MetricInformation {
    /**
     * 
     * @type {string}
     * @memberof MetricInformation
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MetricInformation
     */
    'filters'?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof MetricInformation
     */
    'responseLabels'?: Array<string>;
    /**
     * 
     * @type {MetricInformationRange}
     * @memberof MetricInformation
     */
    'range'?: MetricInformationRange | null;
}
/**
 * 
 * @export
 * @interface MetricInformationForReport
 */
export interface MetricInformationForReport {
    /**
     * 
     * @type {string}
     * @memberof MetricInformationForReport
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MetricInformationForReport
     */
    'displayName': string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MetricInformationForReport
     */
    'filters'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface MetricInformationRange
 */
export interface MetricInformationRange {
    /**
     * timestamp from when to fetch data in UTC
     * @type {string}
     * @memberof MetricInformationRange
     */
    'start': string;
    /**
     * timestamp until when to fetch data in UTC
     * @type {string}
     * @memberof MetricInformationRange
     */
    'end': string;
    /**
     * the number of samples to take in the time range from start to end.
     * @type {number}
     * @memberof MetricInformationRange
     */
    'numOfSamples'?: number;
    /**
     * whether to aggregate the samples or not.
     * @type {boolean}
     * @memberof MetricInformationRange
     */
    'aggregation'?: boolean;
}
/**
 * 
 * @export
 * @interface MetricInformationRangeAllOf
 */
export interface MetricInformationRangeAllOf {
    /**
     * the number of samples to take in the time range from start to end.
     * @type {number}
     * @memberof MetricInformationRangeAllOf
     */
    'numOfSamples'?: number;
}
/**
 * 
 * @export
 * @interface MetricInformationRangeAllOf1
 */
export interface MetricInformationRangeAllOf1 {
    /**
     * whether to aggregate the samples or not.
     * @type {boolean}
     * @memberof MetricInformationRangeAllOf1
     */
    'aggregation'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Range
 */
export interface Range {
    /**
     * timestamp from when to fetch data in UTC
     * @type {string}
     * @memberof Range
     */
    'start': string;
    /**
     * timestamp until when to fetch data in UTC
     * @type {string}
     * @memberof Range
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'description'?: string;
    /**
     * 
     * @type {Array<MetricInformationForReport>}
     * @memberof Report
     */
    'metrics': Array<MetricInformationForReport>;
    /**
     * 
     * @type {Array<Label>}
     * @memberof Report
     */
    'labels'?: Array<Label>;
    /**
     * 
     * @type {Range}
     * @memberof Report
     */
    'range': Range | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Report
     */
    'additionalFields'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdBy': string;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof Report
     */
    'tenantId': number;
    /**
     * 
     * @type {ReportStatus}
     * @memberof Report
     */
    'status': ReportStatus;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'statusUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'statusMessage'?: string;
}
/**
 * 
 * @export
 * @interface ReportCalculatedFields
 */
export interface ReportCalculatedFields {
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'createdBy': string;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof ReportCalculatedFields
     */
    'tenantId': number;
    /**
     * 
     * @type {ReportStatus}
     * @memberof ReportCalculatedFields
     */
    'status': ReportStatus;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'statusUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'statusMessage'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportFilterAndSortFields = {
    Name: 'name',
    CreatedAt: 'createdAt',
    CreatedBy: 'createdBy',
    Status: 'status'
} as const;

export type ReportFilterAndSortFields = typeof ReportFilterAndSortFields[keyof typeof ReportFilterAndSortFields];


/**
 * 
 * @export
 * @interface ReportRequestFields
 */
export interface ReportRequestFields {
    /**
     * 
     * @type {string}
     * @memberof ReportRequestFields
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportRequestFields
     */
    'description'?: string;
    /**
     * 
     * @type {Array<MetricInformationForReport>}
     * @memberof ReportRequestFields
     */
    'metrics': Array<MetricInformationForReport>;
    /**
     * 
     * @type {Array<Label>}
     * @memberof ReportRequestFields
     */
    'labels'?: Array<Label>;
    /**
     * 
     * @type {Range}
     * @memberof ReportRequestFields
     */
    'range': Range | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ReportRequestFields
     */
    'additionalFields'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportStatus = {
    Pending: 'Pending',
    Processing: 'Processing',
    Ready: 'Ready',
    Failed: 'Failed'
} as const;

export type ReportStatus = typeof ReportStatus[keyof typeof ReportStatus];


/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Result
     */
    'metricLabels': { [key: string]: string; };
    /**
     * 
     * @type {Array<Measurement>}
     * @memberof Result
     */
    'measurements': Array<Measurement>;
}
/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get metrics.
         * @param {MetricInformation} metricInformation Requested Metric Information
         * @param {number} [overrideTenantId] The tenant Id of the current request, overrides the tenantId in the header.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (metricInformation: MetricInformation, overrideTenantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricInformation' is not null or undefined
            assertParamExists('getMetrics', 'metricInformation', metricInformation)
            const localVarPath = `/internal/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (overrideTenantId !== undefined) {
                localVarQueryParameter['overrideTenantId'] = overrideTenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get metrics.
         * @param {MetricInformation} metricInformation Requested Metric Information
         * @param {number} [overrideTenantId] The tenant Id of the current request, overrides the tenantId in the header.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(metricInformation: MetricInformation, overrideTenantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(metricInformation, overrideTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get metrics.
         * @param {MetricInformation} metricInformation Requested Metric Information
         * @param {number} [overrideTenantId] The tenant Id of the current request, overrides the tenantId in the header.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(metricInformation: MetricInformation, overrideTenantId?: number, options?: any): AxiosPromise<GetMetrics200Response> {
            return localVarFp.getMetrics(metricInformation, overrideTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary Get metrics.
     * @param {MetricInformation} metricInformation Requested Metric Information
     * @param {number} [overrideTenantId] The tenant Id of the current request, overrides the tenantId in the header.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(metricInformation: MetricInformation, overrideTenantId?: number, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(metricInformation, overrideTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary count reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReports: async (filterBy?: Array<string>, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/reports/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new report.
         * @param {ReportRequestFields} reportRequestFields Report to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (reportRequestFields: ReportRequestFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportRequestFields' is not null or undefined
            assertParamExists('createReport', 'reportRequestFields', reportRequestFields)
            const localVarPath = `/internal/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequestFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportById: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReportById', 'reportId', reportId)
            const localVarPath = `/internal/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportById', 'reportId', reportId)
            const localVarPath = `/internal/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary count reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countReports(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countReports(filterBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new report.
         * @param {ReportRequestFields} reportRequestFields Report to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(reportRequestFields: ReportRequestFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(reportRequestFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportById(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportById(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportById(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportById(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(filterBy, sortBy, sortOrder, offset, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary count reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReports(filterBy?: Array<string>, search?: string, options?: any): AxiosPromise<CountReports200Response> {
            return localVarFp.countReports(filterBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new report.
         * @param {ReportRequestFields} reportRequestFields Report to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(reportRequestFields: ReportRequestFields, options?: any): AxiosPromise<Report> {
            return localVarFp.createReport(reportRequestFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportById(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportById(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReportById(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: any): AxiosPromise<ListReports200Response> {
            return localVarFp.listReports(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary count reports
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public countReports(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).countReports(filterBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new report.
     * @param {ReportRequestFields} reportRequestFields Report to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public createReport(reportRequestFields: ReportRequestFields, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).createReport(reportRequestFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete report
     * @param {string} reportId The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReportById(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReportById(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report
     * @param {string} reportId The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportById(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportById(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List reports
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReports(filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listReports(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(this.axios, this.basePath));
    }
}


