<template>
  <runai-expansion-item class="settings-container" label="Resources" :subheader="sectionSubHeader">
    <template v-for="setting in resourceSettingsValues" :key="setting.key">
      <template v-if="setting.key === SettingKeys.GpuOverProvisioning && resourceSettings.gpuOptimization">
        <toggle-setting
          :setting="resourceSettings.gpuOptimization"
          :disabled="isGpuOptimizationDisabled"
          :tooltip-message="gpuDisabledMessage"
        />
      </template>
      <template v-else>
        <toggle-setting class="q-mb-md" :setting="setting" />
        <q-separator class="q-mb-md hide-if-last-child" />
      </template>
    </template>
  </runai-expansion-item>
</template>

<script lang="ts">
// components
import { defineComponent, type PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";

// utils
import { settingsUtil } from "@/utils/settings.util";

// models
import { SettingKeys, type IResourceSectionSettings } from "@/models/setting.model";

// stores
import { useNodePoolStore } from "@/stores/node-pool.store";
import type { Setting } from "@/swagger-models/backend-client";

export default defineComponent({
  name: "resources-section",
  components: {
    ToggleSetting,
    RunaiExpansionItem,
  },
  props: {
    resourceSettings: {
      type: Object as PropType<IResourceSectionSettings>,
      required: true,
    },
  },
  data() {
    return {
      nodePoolsStore: useNodePoolStore(),
      SettingKeys,
    };
  },
  computed: {
    sectionSubHeader(): string {
      return settingsUtil.getToggleSectionCollapsedTitle(this.resourceSettings);
    },
    isGpuOptimizationDisabled(): boolean {
      return this.nodePoolsStore.hasOverProvisioning && Boolean(this.resourceSettings?.gpuOptimization?.value);
    },
    gpuDisabledMessage(): string {
      return this.isGpuOptimizationDisabled
        ? "To disable GPU resource optimization, first remove GPU resource optimization enforcement from all node pools."
        : "";
    },
    resourceSettingsValues(): Setting[] {
      return Object.values(this.resourceSettings).filter(
        (iResourceSectionSetting: Setting) => iResourceSectionSetting || iResourceSectionSetting === "",
      );
    },
  },
});
</script>
