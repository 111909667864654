import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import type { ClusterReportedStatusPlatformTypeEnum } from "@/swagger-models/cluster-service-client";
import {
  ClusterDisplayedStatusStateEnum,
  type ClusterDisplayedStatus,
  type DisplayedCluster,
} from "@/swagger-models/cluster-service-client";
import { clusterUtil } from "@/utils/cluster.util";
import { dateUtil } from "@/utils/date.util";
import { isDateString } from "@/utils/date.util/date.util";
import { parseCamelCaseAndCapitalize } from "@/utils/string.util/string.util";
import { tableUtil } from "@/utils/table.util";

export const allClusterColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Cluster",
    field: (row: DisplayedCluster) => row.name,
    sortable: true,
    align: "left",
  },
  distribution: {
    name: "distribution",
    label: "Kubernetes distribution",
    field: (row: DisplayedCluster) => row.status?.platform?.type,
    sortable: true,
    align: "left",
    format: (distribution: ClusterReportedStatusPlatformTypeEnum) => clusterUtil.getDistribution(distribution) || "-",
  },
  distributionVersion: {
    name: "distributionVersion",
    label: "Kubernetes version",
    field: (row: DisplayedCluster) => row.status?.platform?.kubeVersion,
    sortable: true,
    align: "left",
    format: (version: string) => version?.replace("v", "") || "-",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: DisplayedCluster) => {
      return row.status;
    },
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    customCellEvent: { emitName: "tooltip-click" },
    format: (status: ClusterDisplayedStatus): IStatusColOptions => {
      return clusterUtil.getStatusColOptions(status);
    },
    sort: (fieldA, fieldB, rowA, rowB) => {
      return tableUtil.customColumnSort(allClusterColumnsMap.status, rowA, rowB, (status: IStatusColOptions) => {
        return status.status;
      });
    },
    exportFormat: (row: DisplayedCluster): string =>
      parseCamelCaseAndCapitalize(row.status?.state as ClusterDisplayedStatusStateEnum) || "NA",
    filterKey: "status",
  },
  lastConnected: {
    name: "lastConnected",
    label: "Last connected",
    field: (row: DisplayedCluster) => {
      return row.status?.state === ClusterDisplayedStatusStateEnum.Connected
        ? "Now"
        : row.lastLiveness
        ? row.lastLiveness
        : "Never";
    },
    sortable: true,
    align: "left",
    format: (lastConnected: string) =>
      isDateString(lastConnected) ? dateUtil.dateAndTimeFormat(new Date(lastConnected)) : lastConnected,
  },
  uuid: {
    name: "uuid",
    label: "Run:ai cluster UUID",
    field: (row: DisplayedCluster) => row.uuid,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: DisplayedCluster) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  url: {
    name: "url",
    label: "URL",
    field: (row: DisplayedCluster) => row.domain,
    sortable: true,
    align: "left",
  },
  version: {
    name: "version",
    label: "Run:ai cluster version",
    field: (row: DisplayedCluster) => row.version,
    sortable: true,
    align: "left",
  },
};

export const allClusterColumns: Array<ITableColumn> = [
  allClusterColumnsMap.name,
  allClusterColumnsMap.distribution,
  allClusterColumnsMap.distributionVersion,
  allClusterColumnsMap.status,
  allClusterColumnsMap.uuid,
  allClusterColumnsMap.createdAt,
  allClusterColumnsMap.url,
  allClusterColumnsMap.version,
  allClusterColumnsMap.lastConnected,
];

export const clusterIndexColumns: Array<ITableColumn> = [
  { ...allClusterColumnsMap.name, display: true, mandatory: true },
  { ...allClusterColumnsMap.distribution, display: true },
  { ...allClusterColumnsMap.distributionVersion, display: true },
  { ...allClusterColumnsMap.status, display: true },
  { ...allClusterColumnsMap.lastConnected, display: true },
  { ...allClusterColumnsMap.createdAt, display: true },
  { ...allClusterColumnsMap.url, display: true },
  { ...allClusterColumnsMap.version, display: true },
  { ...allClusterColumnsMap.uuid, display: false },
];
