import {
  ComputeApi,
  CredentialsApi,
  DatasourcesApi,
  EnvironmentApi,
  GitApi,
  HostPathApi,
  ModelApi,
  ModelInferenceApi,
  NFSApi,
  PVCApi,
  RegistryApi,
  S3Api,
  SecretApi,
  SynchronizationApi,
  TemplateApi,
  TrainingsApi,
  WorkloadApi,
  WorkspacesApi,
  Configuration,
  ConfigMapApi,
  InferencesApi,
  AssetsApi,
  type ConfigurationParameters,
} from "@/swagger-models/assets-service-client";

class AssetsServiceApi {
  configuration: Configuration;

  environmentApi: EnvironmentApi;
  dataSourceApi: DatasourcesApi;
  computeApi: ComputeApi;
  credentialApi: CredentialsApi;
  templateApi: TemplateApi;
  workloadApi: WorkloadApi;
  workspaceApi: WorkspacesApi;
  trainingApi: TrainingsApi;
  s3Api: S3Api;
  pvcApi: PVCApi;
  nfsApi: NFSApi;
  hostPathApi: HostPathApi;
  gitApi: GitApi;
  secretApi: SecretApi;
  modelInferenceApi: ModelInferenceApi;
  modelApi: ModelApi;
  registyApi: RegistryApi;
  synchronizationApi: SynchronizationApi;
  configMapApi: ConfigMapApi;
  inferenceApi: InferencesApi;
  assetsApi: AssetsApi;

  constructor() {
    this.configuration = new Configuration();

    this.environmentApi = new EnvironmentApi(this.configuration, this.baseUrl);
    this.dataSourceApi = new DatasourcesApi(this.configuration, this.baseUrl);
    this.computeApi = new ComputeApi(this.configuration, this.baseUrl);
    this.credentialApi = new CredentialsApi(this.configuration, this.baseUrl);
    this.templateApi = new TemplateApi(this.configuration, this.baseUrl);
    this.workloadApi = new WorkloadApi(this.configuration, this.baseUrl);
    this.workspaceApi = new WorkspacesApi(this.configuration, this.baseUrl);
    this.trainingApi = new TrainingsApi(this.configuration, this.baseUrl);
    this.s3Api = new S3Api(this.configuration, this.baseUrl);
    this.pvcApi = new PVCApi(this.configuration, this.baseUrl);
    this.nfsApi = new NFSApi(this.configuration, this.baseUrl);
    this.hostPathApi = new HostPathApi(this.configuration, this.baseUrl);
    this.gitApi = new GitApi(this.configuration, this.baseUrl);
    this.secretApi = new SecretApi(this.configuration, this.baseUrl);
    this.modelInferenceApi = new ModelInferenceApi(this.configuration, this.baseUrl);
    this.modelApi = new ModelApi(this.configuration, this.baseUrl);
    this.registyApi = new RegistryApi(this.configuration, this.baseUrl);
    this.synchronizationApi = new SynchronizationApi(this.configuration, this.baseUrl);
    this.configMapApi = new ConfigMapApi(this.configuration, this.baseUrl);
    this.inferenceApi = new InferencesApi(this.configuration, this.baseUrl);
    this.assetsApi = new AssetsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const assetsServiceApi = new AssetsServiceApi();
