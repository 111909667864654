import type { ITableColumn } from "@/models/table.model";
import type { ISelectOption } from "@/models/global.model";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import type { ScopeType } from "@/swagger-models/authorization-client";
import { SubjectType, type AccessRule, ResourceTypeGroupId } from "@/swagger-models/authorization-client";
import type { IUser } from "@/models/user.model";
import { ResourceType } from "@/swagger-models/authorization-client";

export enum ERoleName {
  SystemAdmin = "System administrator",
}

export enum EAccessRuleModalPage {
  Project = "project",
  Department = "department",
  User = "user",
  Application = "application",
}

export const subjectTypeOptions: ISelectOption[] = [
  {
    label: "User",
    value: SubjectType.User,
  },
  {
    label: "Application",
    value: SubjectType.App,
  },
];

export const subjectTypeOptionsWithSSO: ISelectOption[] = [
  {
    label: "User",
    value: SubjectType.User,
  },
  {
    label: "SSO Group",
    value: SubjectType.Group,
  },
  {
    label: "Application",
    value: SubjectType.App,
  },
];

export const excludedResourcesTypes: Set<ResourceType> = new Set([
  ResourceType.ClusterConfig,
  ResourceType.Jobs,
  ResourceType.Workspaces,
  ResourceType.Trainings,
]);

export interface IAccessRuleRecords {
  accessRules: AccessRule[];
  displayRecords: number;
  totalRecords: number;
}
export interface IAccessRuleTableModalOptions {
  accessRules: AccessRule[];
  columns: Array<ITableColumn>;
  header: string;
  loading: boolean;
}

export interface IAccessRuleManagementModalOptions {
  subjectId?: string;
  subjectType?: SubjectType;
  scopeType?: ScopeType;
  scopeName?: string;
  scopeId?: string;
  page: EAccessRuleModalPage;
  user?: IUser;
}

export interface IAccessRuleDisplayConfig {
  subject: boolean;
  users: boolean;
  roles: boolean;
  scope: boolean;
}

export interface ISubjectTypeOption extends ISelectOption {
  value: SubjectType;
}

export interface IRoleIdOption extends ISelectOption {
  value: number;
}
export interface IAccessRuleItem {
  subjectTypeOption: null | ISubjectTypeOption;
  roleOption: null | IRoleIdOption;
  scopeInput: null | IOrgTreeNodeId;
  scopeName?: string;
  scopeType?: ScopeType;
  subjectType?: SubjectType;
  roleName?: string;
  subjectId: string;
  accessRuleId?: number;
  saved: boolean;
  isInherited?: boolean;
}

export interface AccessRuleWithGroup extends AccessRule {
  group: string;
}

export const accessRuleSubjectTypeFilterOptions: ISelectOption[] = Object.entries(SubjectType).map(([key, value]) => ({
  label: key,
  value: value,
}));

/**
 * Order of resource types.
 * This array defines the order in which resource types should be displayed in the role permissions table.
 *
 * @type {ResourceTypeGroupId[]}
 */
export const ResourceTypeGroupIdOrder = [
  ResourceTypeGroupId.Organization,
  ResourceTypeGroupId.PhysicalResource,
  ResourceTypeGroupId.Iam,
  ResourceTypeGroupId.Dashboard,
  ResourceTypeGroupId.Workload,
  ResourceTypeGroupId.WorkloadAsset,
];
