<template>
  <q-badge :aid="badgeAid" class="badge" outline align="middle" :class="badgeColor" :style="badgeStyle">
    {{ badge }}
    <q-tooltip v-if="displayTooltip" max-width="300px" anchor="bottom end">{{ badgeTooltip }}</q-tooltip>
  </q-badge>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { EBadgeLabel } from "@/models/setting.model";

export default defineComponent({
  name: "runai-stage-badge",
  props: {
    badge: {
      type: String as PropType<EBadgeLabel>,
      required: true,
    },
    displayTooltip: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  computed: {
    badgeAid(): string {
      return `badge-${this.badge}`;
    },
    badgeStyle(): { padding: string; color?: string } {
      return { padding: this.badgePadding, ...(this.color ? { color: this.color } : {}) };
    },
    badgeColor(): string {
      if (this.color) return "";
      switch (this.badge) {
        case EBadgeLabel.Experimental:
          return "experimental";
        case EBadgeLabel.Beta:
          return "beta";
        case EBadgeLabel.Legacy:
          return "legacy";
        default:
          return "";
      }
    },
    badgeTooltip(): string {
      switch (this.badge) {
        case EBadgeLabel.Experimental:
          return "This feature is in early development. It may not be stable and could be removed or changed significantly in future versions. Use with caution.";
        case EBadgeLabel.Beta:
          return "This feature is still being developed for official release in a future version and may have some limitations. Use with caution.";
        case EBadgeLabel.Legacy:
          return "This feature is scheduled to be removed in future versions. We recommend using alternatives if available. Use only if necessary.";
        default:
          return "";
      }
    },
    badgePadding(): string {
      switch (this.badge) {
        case EBadgeLabel.Experimental:
          return "1px 10px";
        case EBadgeLabel.Beta:
          return "1px 30px";
        case EBadgeLabel.Legacy:
          return "1px 24px";
        default:
          return "";
      }
    },
  },
});
</script>

<style scoped lang="scss">
.badge {
  cursor: pointer;
  font-size: 12px;
  width: 90px;
}
.legacy {
  color: $black-54 !important;
}
.experimental {
  color: $lighter-green !important;
}
.beta {
  color: $runai-orange !important;
}
.emptyBadge {
  display: none;
  border: white;
}
</style>
