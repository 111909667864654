<template>
  <runai-filter-chip
    title="Cluster"
    :value="clusterName"
    @save="onClusterFilterChanged"
    @close="selectedClusterId = filterValue"
  >
    <template v-slot:input-component>
      <runai-radio-options
        class="cluster-radio-buttons"
        v-model="selectedClusterId"
        :options="options"
        option-max-width="200px"
      ></runai-radio-options>
    </template>
  </runai-filter-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";
// stores
import { useClusterStore } from "@/stores/cluster.store";
// cmps
import { RunaiFilterChip } from "@/components/common/runai-filter-chip";
// type
import type { ISelectOption } from "@/models/global.model";

import { RunaiRadioOptions } from "@/components/common/runai-radio-options";
import { ALL_CLUSTER_FILTER_VALUE } from "@/models/filter.model";
import type { DisplayedCluster } from "@/swagger-models/cluster-service-client";

const allOption: ISelectClusterOption = { label: "All", value: ALL_CLUSTER_FILTER_VALUE };

interface ISelectClusterOption extends ISelectOption {
  value: string;
}

export default defineComponent({
  components: {
    RunaiFilterChip,
    RunaiRadioOptions,
  },
  emits: ["update-filter"],
  props: {
    filterValue: {
      type: String as PropType<string>,
      required: true,
    },
    hideAllOption: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      selectedClusterId: "" as ISelectClusterOption["value"],
      thumbStyle: {
        right: "4px",
        borderRadius: "4px",
        backgroundColor: "#00000089",
        width: "7px",
        opacity: 1,
      },
    };
  },
  created() {
    this.initSelectedClusterId();
  },
  computed: {
    options(): ISelectClusterOption[] {
      const options: ISelectClusterOption[] = this.clusterStore.clusterList
        .map((c: DisplayedCluster) => {
          return { label: c.name, value: c.uuid };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      if (!this.hideAllOption) {
        options.unshift(allOption);
      }
      return options;
    },
    clusterName(): string {
      const defaultLabel = this.hideAllOption ? this.defaultClusterName : "All";
      const selectedClusterLabel = this.options.find(
        (opt: ISelectClusterOption) => opt.value === this.filterValue,
      )?.label;
      return selectedClusterLabel || defaultLabel;
    },
    defaultClusterName(): string {
      return this.clusterStore.clusterList[0].name;
    },
    defaultClusterId(): string {
      return this.clusterStore.clusterList[0].uuid;
    },
  },
  methods: {
    initSelectedClusterId(): void {
      if (this.filterValue && this.clusterStore.clusterList.some((c: DisplayedCluster) => c.uuid === this.filterValue)) {
        this.selectedClusterId = this.filterValue;
      } else {
        this.selectedClusterId = this.hideAllOption ? this.defaultClusterId : ALL_CLUSTER_FILTER_VALUE;
        this.onClusterFilterChanged();
      }
    },
    onClusterFilterChanged(): void {
      if (this.selectedClusterId === this.filterValue) return;

      this.clusterStore.setSelectedClusterById(this.selectedClusterId);
      this.$emit("update-filter", this.selectedClusterId);
    },
  },
  watch: {
    filterValue() {
      this.initSelectedClusterId();
    },
  },
});
</script>

<style lang="scss">
.cluster-radio-buttons {
  max-height: 35vh;
  overflow: scroll;
}
</style>
