// Object to store interval references
import type { EIntervalLabels } from "@/models/interval.model";
import { intervalLabelTimeMap } from "@/models/interval.model";

const intervalRegistry: { [key: string]: number } = {};

export const intervalUtil = { startInterval, stopInterval, displayActiveIntervals };

// Function to start an interval
function startInterval(label: EIntervalLabels, callback: () => void): number {
  if (!intervalLabelTimeMap[label]) {
    throw new Error(`Interval label ${label} not found in intervalLabelTimeMap`);
  }
  const intervalId = setInterval(callback, intervalLabelTimeMap[label]);
  // Store the interval ID in the registry
  stopInterval(label); // in case we have already interval we want to clear and remove him
  intervalRegistry[label] = Number(intervalId);
  return intervalRegistry[label];
}

// Function to stop an interval by its label
function stopInterval(label: EIntervalLabels): void {
  if (!intervalLabelTimeMap[label]) {
    throw new Error(`Interval label ${label} not found in intervalLabelTimeMap`);
  }
  if (intervalRegistry[label]) {
    clearInterval(intervalRegistry[label]);
    delete intervalRegistry[label];
  }
}

function displayActiveIntervals(): void {
  const activeLabels = Object.keys(intervalRegistry);
  console.log("Active Interval Labels:", activeLabels);
}

function stopAllIntervals(): void {
  Object.keys(intervalRegistry).forEach((label) => stopInterval(label as EIntervalLabels));
  console.log("intervals stopped");
}

function stopIntervalByLabel(label: EIntervalLabels): void {
  stopInterval(label);
  console.log(`${label} interval stopped`);
}

/*
   make displayActiveIntervals available in the browser console
 */
declare global {
  interface Window {
    displayActiveIntervals: () => void;
    stopAllIntervals: () => void;
    stopIntervalByLabel: (label: EIntervalLabels) => void;
  }
}

window.displayActiveIntervals = displayActiveIntervals;
window.stopAllIntervals = stopAllIntervals;
window.stopIntervalByLabel = stopIntervalByLabel;
