<template>
  <template v-if="policyRules?.options?.length">
    <div class="row q-gutter-lg">
      <policy-string-field
        aid="claim-size-select"
        class="col-4"
        :model-value="modelValue"
        :disable="disable"
        :policy-rules="policyRules"
        stack-label
        label="Claim size"
        :rules="[isSelected]"
        @update:model-value="updateSize"
      />
    </div>
  </template>
  <template v-else>
    <section class="volume-size-section row q-gutter-sm">
      <q-input
        aid="claim-size-input"
        type="number"
        class="col-2"
        :model-value="sizeNum"
        no-error-icon
        label="Claim size"
        stack-label
        :rules="[sizeNotEmpty, largerThanZero]"
        @update:model-value="handleSizeChange(Number($event))"
        :disable="disable"
      />

      <runai-select
        aid="unit-select"
        class="col-2"
        standard
        :model-value="selectedUnit"
        label="Units"
        stack-label
        option-label="label"
        map-options
        emit-value
        :options="unitsOptions"
        placeholder="Units"
        :rules="[isSelected]"
        @update:model-value="handleUnitChange"
        :disable="disable"
        unclearable
      />
    </section>
  </template>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
import { PolicyStringField } from "@/components/common/policy-string-field";
// Constants
import { errorMessages } from "@/common/error-message.constant";
// Utils
import { isNotEmpty, isNumberGreaterThanZero } from "@/common/form.validators";
// Models
import { EMemoryUnitValue, allMemoryUnitOptions } from "@/models/global.model";
import { separateDigitsAndLetters } from "@/utils/common.util";
import type { StringRules } from "@/swagger-models/policy-service-client";

const DEFAULT_SIZE_NUM = 1;

export default defineComponent({
  name: "volume-size-section",
  components: { RunaiSelect, PolicyStringField },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    policyRules: {
      type: [Object, null] as PropType<StringRules | null>,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      sizeNum: DEFAULT_SIZE_NUM as number,
      selectedUnit: EMemoryUnitValue.GB as string,
      unitsOptions: allMemoryUnitOptions,
    };
  },
  created() {
    if (this.modelValue) {
      const [sizeNum, selectedUnit] = separateDigitsAndLetters(this.modelValue);
      this.sizeNum = +sizeNum;
      this.selectedUnit = selectedUnit || EMemoryUnitValue.GB;
    }
  },
  methods: {
    handleSizeChange(val: number): void {
      this.sizeNum = +val;
      this.updateSize(val + this.selectedUnit);
    },
    handleUnitChange(val: string): void {
      this.selectedUnit = val;
      this.updateSize(this.sizeNum + val);
    },
    updateSize(size: string): void {
      this.$emit("update:model-value", size);
    },
    // Rules
    sizeNotEmpty(val: number): boolean | string {
      return (val !== undefined && !Number.isNaN(val) && val !== null) || errorMessages.ENTER_A_VALUE;
    },
    largerThanZero(val: number): boolean | string {
      return isNumberGreaterThanZero(val) || errorMessages.NUMBER_GREATER_THAN_ZERO;
    },
    isSelected(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.SELECT_A_VALUE;
    },
  },
});
</script>
