import {
  Configuration,
  WorkloadsApi,
  type ConfigurationParameters,
  PodsApi,
  EventsApi,
  DistributedApi,
  TrainingsApi,
  WorkspacesApi,
  InferencesApi,
  WorkloadsBatchApi,
  UnifiedApi,
} from "@/swagger-models/workloads-client";

class WorkloadServiceApi {
  configuration: Configuration;

  eventsApi: EventsApi;
  podsApi: PodsApi;
  workloadsApi: WorkloadsApi;
  distributedApi: DistributedApi;
  trainingsApi: TrainingsApi;
  workspacesApi: WorkspacesApi;
  inferencesApi: InferencesApi;
  workloadsBatchApi: WorkloadsBatchApi;
  unifiedApi: UnifiedApi;

  constructor() {
    this.configuration = new Configuration();

    this.eventsApi = new EventsApi(this.configuration, this.baseUrl);
    this.podsApi = new PodsApi(this.configuration, this.baseUrl);
    this.workloadsApi = new WorkloadsApi(this.configuration, this.baseUrl);
    this.workloadsBatchApi = new WorkloadsBatchApi(this.configuration, this.baseUrl);
    this.distributedApi = new DistributedApi(this.configuration, this.baseUrl);
    this.trainingsApi = new TrainingsApi(this.configuration, this.baseUrl);
    this.workspacesApi = new WorkspacesApi(this.configuration, this.baseUrl);
    this.inferencesApi = new InferencesApi(this.configuration, this.baseUrl);
    this.unifiedApi = new UnifiedApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const workloadServiceApi = new WorkloadServiceApi();
