import type { BreadcrumbsFormatterCallbackFunction } from "highcharts";

export enum CHART_COLOR_PALETTE {
  // Don't change the order of the colors!!!
  // The order of the colors is important for the chart it will be consistent and look nice.
  ELECTRIC_SKY_BLUE = "#0BB4FF",
  ROYAL_PURPLE = "#B048F3",
  LIVELY_LIME = "#50E991",
  CORAL_ORANGE = "#FF8552",
  STORMY_LAVENDER = "#6075AF",
  GOLDEN_APRICOT = "#EBAD5D",
  MIDNIGHT_INDIGO = "#372FB5",
  AQUAMARINE = "#55D6BE",
  MAGENTA_BERRY = "#D336B9",
}

export const TURQUOISE = "#208EB7";

export enum SINGLE_STAT_COLOR_PALETTE {
  GREEN = "#4CAF50",
  RED = "#EF5350",
  YELLOW = "#FFAB00",
}

export enum EChartType {
  COLUMN = "column",
  STACKED_COLUMN = "stacked_column",
  MULTI_BAR = "multi_bar",
}

//dashboard widgets
export enum EWidgetComponent {
  WorkloadStatus = "WorkloadStatusWidget",
  RunningWorkloads = "RunningWorkloadsWidget",
  PendingWorkloads = "PendingWorkloadsWidget",
  IdleAllocatedGpuWidget = "IdleAllocatedGpuWidget",
  ResourceAllocationTimeRangeWidget = "ResourceAllocationTimeRangeWidget",
  ResourceUtilizationTimeRangeWidget = "ResourceUtilizationTimeRangeWidget",
  NodePoolFreeResourcesWidget = "NodePoolFreeResourcesWidget",
  ResourceAllocationByWorkloadWidget = "ResourceAllocationByWorkloadWidget",
  ReadyNodesWidget = "ReadyNodesWidget",
  ReadyGpuWidget = "ReadyGpuWidget",
  AllocatedGpuWidget = "AllocatedGpuWidget",
  GpuAllocationRatioWidget = "GpuAllocationRatioWidget",
}

export enum EWidgetEntity {
  Department = "Department",
  Nodepool = "Node pool",
  Node = "Node",
  Workload = "Workload",
}

export type TMeasurementsTimeAndValue = [number, number | null];

export const chartColorPaletteArray = Object.entries(CHART_COLOR_PALETTE).map(([, value]) => value);
export const TOOLTIP_TEXT_COLOR = "#FFFFFF";
export const ACTIVE_AXIS_LABEL_COLOR = "#0654fe";
export const TOOLTIP_BASE_COLOR = "#46465C";
export const UNKNOWN_COLOR = CHART_COLOR_PALETTE.ROYAL_PURPLE;

export interface IChartData {
  name: string;
  y: number;
  drilldown?: string;
}

export interface IChartSeries {
  name: string;
  data: Array<IChartData>;
}

export interface IDrilldownSeries {
  id: string;
  data: [string, number][];
}

export interface IColumnChartSeries {
  title: string;
  data: number[];
  tooltipTitle?: string;
}

export interface IColorConfigurationItem {
  from: number;
  to: number;
  color: SINGLE_STAT_COLOR_PALETTE;
}

export interface IWidgetWrapperOptions {
  title: string;
  timeFrame: string;
  linkText?: string;
  entityType?: EWidgetEntity;
  tooltipText?: string;
}
export interface IChartWidgetOptions {
  type: "column" | "line" | "area" | "scatter" | "packedbubble";
  height: number;
  sharedTooltip?: boolean;
  showSharedCrosshair?: boolean;
  yAxisTitle: string;
  showYAxisPercentage?: boolean;
  yAxisFormatFunction?: (value: string | number) => string;
  drilldownFormatter?: BreadcrumbsFormatterCallbackFunction;
}

export interface ITooltipPoint {
  name: string;
  y: string | number | null | undefined;
  color?: string;
  symbol?: string;
}

export interface IZoomEvent {
  max: number;
  min: number;
  chartId: string;
}

export enum EYAxisLabelFormat {
  Memory,
}
