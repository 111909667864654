<template>
  <runai-expansion-item label="Quota management" default-opened :loading="loading">
    <template #subheader>
      {{ expansionSubHeader }}
    </template>
    <slot name="over-quota-toggle"></slot>
    <div class="q-my-md">
      <span class="block q-my-md">{{ header }}</span>
      <span class="text-italic"
        >For more information, see the
        <a target="_blank" href="https://docs.run.ai/latest/Researcher/scheduling/the-runai-scheduler/"
          >Run:ai Scheduler</a
        >
        guide</span
      >
    </div>
    <quota-management-table
      :read-only="readOnly"
      :department-id="departmentId"
      :cluster-id="clusterId"
      :resources="resources"
      :node-pools-priorities="nodePoolsPriorities || []"
      :quota-statuses="quotaStatuses"
      @update:resources="$emit('update:resources', $event)"
      @update:node-pools-priorities="$emit('update:node-pools-priorities', $event)"
    />
  </runai-expansion-item>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

// cmps
import { QuotaManagementTable } from "@/components/quota-management/quota-management-table";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
// utils
import { resourceUtil } from "@/utils/resource.util";

// models
import { EQuotaEntity, EResourceLabel, EResourceType } from "@/models/resource.model";
//store
import { useSettingStore } from "@/stores/setting.store";
import type { QuotaStatusNodePool, Resources } from "@/swagger-models/org-unit-service-client";

export default defineComponent({
  components: { QuotaManagementTable, RunaiExpansionItem },
  emits: ["update:resources", "update:node-pools-priorities"],
  props: {
    departmentId: {
      type: String as PropType<string>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      default: "",
    },
    resources: {
      type: Array as PropType<Array<Resources>>,
      required: true,
    },
    nodePoolsPriorities: {
      type: [Array, null] as PropType<Array<string> | null>,
      required: false,
    },
    entity: {
      type: String as PropType<EQuotaEntity>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<QuotaStatusNodePool>>,
      default: () => [],
    },
  },
  provide() {
    return {
      entity: this.entity,
    };
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  EQuotaEntity: EQuotaEntity,
  computed: {
    isOnlyDefaultNodePool(): boolean {
      return this.resources.length === 1;
    },
    totalGpus(): string | number {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.GPU);
    },
    totalCpus(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.CPU);
    },
    totalMemory(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.MEMORY);
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isProjectEntity(): boolean {
      return this.entity === EQuotaEntity.project;
    },
    header(): string {
      return `Click the boxes below to set the ${this.isProjectEntity ? "project’s" : "department's"} quota`;
    },
    expansionSubHeader(): string {
      if (this.isCpuEnabled) {
        return `${EResourceLabel.GPU} ${this.totalGpus} / ${EResourceLabel.CPU} ${this.totalCpus} / ${EResourceLabel.MEMORY} ${this.totalMemory}`;
      }
      return `${EResourceLabel.GPU} ${this.totalGpus} `;
    },
  },
});
</script>
