import {
  format,
  parse,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  parseISO,
  endOfDay,
  isToday,
  setSeconds,
  startOfDay,
} from "date-fns";
import {
  PREDEFINED_DATES,
  type DateTimeFormatOptions,
  type PredefinedOption,
  PREDEFINED_HOURS,
  PREDEFINED_MINUTES,
} from "@/models/date.model";

export enum TimeUnit {
  minute = 0,
  hour = 1,
  day = 2,
  week = 3,
  month = 4,
  year = 5,
}

export const defaultPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_DATES.TODAY, daysCount: 0 },
  {
    label: PREDEFINED_DATES.YESTERDAY,
    daysCount: 1,
    hoursCount: 0,
    endDate: adjustDateBy(TimeUnit.day, new Date(), -1),
  },
  { label: PREDEFINED_DATES.LAST_7_DAYS, daysCount: 7 },
  { label: PREDEFINED_DATES.LAST_30_DAYS, daysCount: 30 },
];

export const hoursGranularityPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_HOURS.LAST_HOUR, hoursCount: 1 },
  { label: PREDEFINED_HOURS.LAST_6_HOURS, hoursCount: 6 },
  ...defaultPredefinedOptions,
];

export const extendedDefaultPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_DATES.TODAY, hoursCount: 0 },
  {
    label: PREDEFINED_DATES.YESTERDAY,
    daysCount: 1,
    endDate: adjustDateBy(TimeUnit.day, new Date(), -1),
  },
  { label: PREDEFINED_DATES.LAST_7_DAYS, daysCount: 7 },
  { label: PREDEFINED_DATES.LAST_14_DAYS, daysCount: 14 },
  { label: PREDEFINED_DATES.LAST_30_DAYS, daysCount: 30 },
];

export const extendedHoursPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_HOURS.LAST_3_HOURS, hoursCount: 3 },
  { label: PREDEFINED_HOURS.LAST_6_HOURS, hoursCount: 6 },
  { label: PREDEFINED_HOURS.LAST_24_HOURS, hoursCount: 24 },
  ...extendedDefaultPredefinedOptions,
];

export const minutesGranularityPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_MINUTES.LAST_15_MINUTES, minutesCount: 15 },
];

export const workloadsLifespanPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_DATES.WORKLOADS_LIFESPAN },
];

export const dateUtil = {
  adjustDateBy,
  adjustToEndOfTheDay,
  adjustToStartOfTheDay,
  dateAndTimeFormat,
  dateFormat,
  differenceBy,
  parseToDate,
  timeAgo,
  moreThanHourFromNow,
  isSameDay,
  formatDuration,
  formatToUTCDate,
  getEpochDates,
  dateToTimestamp,
  formatDateToDayMonth,
  convertDateToISO,
  dateSevenDaysAgo,
  getTimeframePreview,
  getEndOfDay,
  getStartOfDay,
  isDateIsToday,
  setSecondsToDate,
  disablePredefinedOptionsBeforeDate,
  getUpdatedTime,
  formatTimestamp,
  isDurationMoreThanMinute,
  formatDateRange,
  rangeOfDatesCallback,
};

// TODO: need to move it to date.model.
// now I can do that becuase date.model have date.util import and we
// need to import this type to date.util (from the date.model) - will
// have circular dependencies
export interface IRangeDates {
  dateStart: Date;
  dateEnd: Date;
  label: string;
}

function dateFormat(date: Date, dateFormat = "dd/MM/yyyy"): string {
  if (!date) return "";
  return format(date, dateFormat);
}

function formatToUTCDate(date: Date): string {
  // This is a workaround for the fact that the date-fns library does not support UTC dates in simple way
  // We need it more to send to server, so the format is always the same "yyyy-MM-dd hh:mm:ss.SSS"
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

function formatDateToDayMonth(dateString: string): string {
  const parsedDate = parseISO(dateString);
  return format(parsedDate, "dd/MM");
}

function convertDateToISO(date: Date): string {
  return date.toISOString();
}

function dateAndTimeFormat(date: Date, options: DateTimeFormatOptions = {}): string {
  if (!date) return "";

  const { includeSeconds = false, includeMilliseconds = false } = options;

  const secondsFormat = includeSeconds ? ":ss" : "";
  const millisecondsFormat = includeMilliseconds ? ":SSS" : "";

  const formatString = `M/dd/yyyy, HH:mm${secondsFormat}${millisecondsFormat}`;
  return format(date, formatString);
}
function adjustToStartOfTheDay(date: Date): void {
  date.setHours(0, 0, 0, 0);
}

function adjustToEndOfTheDay(date: Date): void {
  date.setHours(23, 59, 59, 999);
}

function parseToDate(dateToParse: string, format: string): Date | null {
  if (!dateToParse) return null;
  return parse(dateToParse, format, new Date());
}

function differenceBy(timeUnit: TimeUnit = TimeUnit.hour, endDate: Date | number, startDate: Date | number): number {
  if (!startDate || !endDate) return 0;
  switch (timeUnit) {
    case TimeUnit.minute:
      return differenceInMinutes(endDate, startDate);
    case TimeUnit.day:
      return differenceInDays(endDate, startDate);
    case TimeUnit.week:
      return differenceInWeeks(endDate, startDate);
    case TimeUnit.month:
      return differenceInMonths(endDate, startDate);
    case TimeUnit.year:
      return differenceInYears(endDate, startDate);
    default:
      return differenceInHours(endDate, startDate);
  }
}

function adjustDateBy(timeUnit: TimeUnit, date: Date | number, amount: number): Date {
  switch (timeUnit) {
    case TimeUnit.minute:
      return addMinutes(date, amount);
    case TimeUnit.hour:
      return addHours(date, amount);
    case TimeUnit.day:
      return addDays(date, amount);
    case TimeUnit.week:
      return addWeeks(date, amount);
    case TimeUnit.month:
      return addMonths(date, amount);
    case TimeUnit.year:
      return addYears(date, amount);
    default:
      return addHours(date, amount);
  }
}

// TODO: Convert this to date-fns
function timeAgo(timestamp: number | string | Date): string {
  const date = new Date(timestamp);
  let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // compute residual after removing whole days, hours and minutes
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  let ret = "";
  if (days > 0) {
    ret += days + "d";
  }
  if (hours > 0 || (days > 0 && minutes > 0)) {
    if (ret) {
      ret += "-";
    }
    ret += hours + "h";
  }
  if (minutes > 0) {
    if (ret) {
      ret += "-";
    }
    ret += minutes + "m";
  }
  if (!ret) {
    ret += seconds + "s";
  }
  return ret;
}

function moreThanHourFromNow(creationTimestamp: number): boolean {
  if (!creationTimestamp) return false;

  const oneHourInMs = 3600000;
  const dateNow: number = new Date().getTime();
  return dateNow - Number(creationTimestamp) > oneHourInMs;
}

function isDurationMoreThanMinute(durationInSeconds: number): boolean {
  return durationInSeconds > 60;
}

function isSameDay(timestamp1: number, timestamp2: number): boolean {
  const date1: Date = new Date(timestamp1);
  const date2: Date = new Date(timestamp2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function formatDuration(seconds: number): string {
  const date = new Date(seconds * 1000);
  const days = Math.floor(seconds / (3600 * 24));
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${days}d-${hours}h-${minutes}m`;
}

/**
 * Formats a given duration in seconds into a human-readable string.
 *
 * The format will include days, hours, and minutes if applicable.
 * For example, 90061 seconds will be formatted as "1d 1h 1min".
 *
 * @param {number} seconds - The duration in seconds to format.
 * @returns {string} - The formatted duration string.
 */
function formatTimestamp(seconds: number): string {
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds %= 24 * 60 * 60;
  const hours = Math.floor(seconds / (60 * 60));
  seconds %= 60 * 60;
  const minutes = Math.floor(seconds / 60);

  let result = "";
  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  if (minutes > 0) result += `${minutes}min`;
  if (result === "") result = "0min";
  return result.trim();
}

function getEpochDates(start: string, end: string): { startDate: number; endDate: number } {
  const startDateObj = new Date(start);
  const endDateObj = new Date(end);

  // Normalize to UTC
  const startDateUTC = new Date(
    Date.UTC(
      startDateObj.getUTCFullYear(),
      startDateObj.getUTCMonth(),
      startDateObj.getUTCDate(),
      startDateObj.getUTCHours(),
      startDateObj.getUTCMinutes(),
      startDateObj.getUTCSeconds(),
    ),
  );
  const endDateUTC = new Date(
    Date.UTC(
      endDateObj.getUTCFullYear(),
      endDateObj.getUTCMonth(),
      endDateObj.getUTCDate(),
      endDateObj.getUTCHours(),
      endDateObj.getUTCMinutes(),
      endDateObj.getUTCSeconds(),
    ),
  );

  const startDate: number = Math.floor(startDateUTC.getTime() / 1000);
  const endDate: number = Math.floor(endDateUTC.getTime() / 1000);

  return { startDate, endDate };
}

export function isDateString(str: string): boolean {
  const date: Date = new Date(str);
  return !isNaN(date.getTime());
}
function dateToTimestamp(date?: string): number {
  return +new Date(date || 0);
}

function dateSevenDaysAgo(): Date {
  return dateUtil.adjustDateBy(TimeUnit.day, new Date(), -7);
}

function getTimeframePreview(rangeDates: IRangeDates): string {
  const FULL_DATE_FORMAT = "MMM dd, yyyy";
  const SHORT_DATE_FORMAT = "MMM dd";
  const getFullYear = (date: Date): string => dateUtil.dateFormat(date, "yyyy");

  if (rangeDates.label !== "Custom") return rangeDates.label;

  const startYear = getFullYear(rangeDates.dateStart);
  const endYear = getFullYear(rangeDates.dateEnd);

  if (startYear === endYear) {
    return `${dateUtil.dateFormat(rangeDates.dateStart, SHORT_DATE_FORMAT)} - ${dateUtil.dateFormat(
      rangeDates.dateEnd,
      FULL_DATE_FORMAT,
    )}`;
  }

  return `${dateUtil.dateFormat(rangeDates.dateStart, FULL_DATE_FORMAT)} - ${dateUtil.dateFormat(
    rangeDates.dateEnd,
    FULL_DATE_FORMAT,
  )}`;
}

function getEndOfDay(date: Date): Date {
  return endOfDay(date);
}

function getStartOfDay(date: Date): Date {
  return startOfDay(date);
}

function isDateIsToday(date: Date): boolean {
  return isToday(date);
}

function setSecondsToDate(date: Date, seconds: number): Date {
  return setSeconds(date, seconds);
}

function disablePredefinedOptionsBeforeDate(options: PredefinedOption[], creationDate: Date): PredefinedOption[] {
  const now = new Date();
  return options.map((option: PredefinedOption) => {
    const hoursDiff = Math.abs(differenceBy(TimeUnit.hour, now, creationDate));
    const daysDiff = Math.abs(differenceBy(TimeUnit.day, now, creationDate));
    const midnight = getEndOfDay(new Date(now.getDate() - 1));

    if (option.hoursCount === 1 || option.daysCount === 0) {
      //LAST_HOUR, TODAY
      return { ...option, disabled: false };
    } else if (option.daysCount === 1 && creationDate < midnight) {
      //YESTERDAY
      return { ...option, disabled: false };
    } else if (option.hoursCount != undefined && option.hoursCount > 0) {
      //LAST_3_HOURS, LAST_6_HOURS, LAST_24_HOURS
      return { ...option, disabled: hoursDiff < option.hoursCount };
    } else if (option.daysCount != undefined && option.daysCount > 0) {
      //LAST_7_DAYS, LAST_14_DAYS, LAST_30_DAYS
      return { ...option, disabled: daysDiff < option.daysCount };
    } else {
      return { ...option, disabled: false };
    }
  });
}

function getUpdatedTime(dateRange: IRangeDates): IRangeDates {
  switch (dateRange.label) {
    case PREDEFINED_DATES.LAST_14_DAYS:
    case PREDEFINED_DATES.LAST_7_DAYS:
    case PREDEFINED_DATES.LAST_30_DAYS:
    case PREDEFINED_DATES.WORKLOADS_LIFESPAN:
    case PREDEFINED_DATES.TODAY: {
      return { ...dateRange, dateEnd: new Date() };
    }

    case PREDEFINED_DATES.CUSTOM: {
      if (dateUtil.isDateIsToday(dateRange.dateEnd)) {
        return { ...dateRange, dateEnd: new Date() };
      }
      return dateRange;
    }

    case PREDEFINED_MINUTES.LAST_15_MINUTES:
    case PREDEFINED_HOURS.LAST_24_HOURS:
    case PREDEFINED_HOURS.LAST_3_HOURS:
    case PREDEFINED_HOURS.LAST_6_HOURS:
    case PREDEFINED_HOURS.LAST_HOUR: {
      const sub: number = dateRange.dateEnd.getTime() - dateRange.dateStart.getTime();
      const dateEnd: Date = new Date();
      return { ...dateRange, dateEnd, dateStart: new Date(dateEnd.getTime() - sub) };
    }

    case PREDEFINED_DATES.YESTERDAY:
    default: {
      return dateRange;
    }
  }
}

/**
 * Formats a date range into a string.
 *
 * This function takes two date strings and a format string, and returns a formatted string
 * representing the date range. The default format is "M/dd/yyyy".
 *
 * @param {string} from - The start date of the range.
 * @param {string} to - The end date of the range.
 * @param {string} [formatString="M/dd/yyyy"] - The format string to use for formatting the dates.
 * @returns {string} - The formatted date range string.
 */
function formatDateRange(from: string, to: string, formatString = "M/dd/yyyy"): string {
  const startDate = format(new Date(from), formatString);
  const endDate = format(new Date(to), formatString);
  return `${startDate} - ${endDate}`;
}

/**
 * Callback function to validate a date range.
 *
 * This function checks if a given date falls within a specified date range.
 * It also ensures that the date range does not exceed a maximum period.
 *
 * @param {string} date - The date to validate.
 * @param {string} start - The start date of the range.
 * @param {string} end - The end date of the range.
 * @param {boolean} isStart - A flag indicating if the date is the start date of the range.
 * @param {number} maxPeriodInMs - The maximum allowed period in milliseconds.
 * @returns {boolean} - Returns true if the date is valid within the range and period, otherwise false.
 */
function rangeOfDatesCallback(
  date: string,
  start: string,
  end: string,
  isStart: boolean,
  maxPeriodInMs: number,
): boolean {
  const selectedDate = new Date(date);
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();

  if (isStart) {
    const isBeforeEndDate = selectedDate < endDate;
    const isWithinNinetyDays = endDate.getTime() - selectedDate.getTime() < maxPeriodInMs;
    return isBeforeEndDate && isWithinNinetyDays;
  } else {
    const isAfterStartDate = selectedDate > startDate;
    const isBeforeOrEqualNow = selectedDate <= now;
    const isWithinNinetyDays = selectedDate.getTime() - startDate.getTime() < maxPeriodInMs;
    return isAfterStartDate && isBeforeOrEqualNow && isWithinNinetyDays;
  }
}
