/* tslint:disable */
/* eslint-disable */
/**
 * Audit
 * An API for querying audit logs
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'timestamp': string;
    /**
     * the ID of the tenant
     * @type {string}
     * @memberof AuditLog
     */
    'tenant_id': string;
    /**
     * the user/app which triggered this API
     * @type {string}
     * @memberof AuditLog
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'subject_type': AuditLogSubjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'source_ip': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'action': AuditLogActionEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'http_method': AuditLogHttpMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'entity_type': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'entity_name': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'entity_id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'result': AuditLogResultEnum;
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    'http_status_code': number;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'cluster_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'cluster_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'request_id'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AuditLog
     */
    'metadata'?: { [key: string]: string; };
}

export const AuditLogSubjectTypeEnum = {
    User: 'User',
    App: 'App'
} as const;

export type AuditLogSubjectTypeEnum = typeof AuditLogSubjectTypeEnum[keyof typeof AuditLogSubjectTypeEnum];
export const AuditLogActionEnum = {
    Create: 'create',
    Update: 'update',
    Delete: 'delete',
    SignIn: 'sign-in',
    SignOut: 'sign-out',
    ResetPassword: 'reset-password'
} as const;

export type AuditLogActionEnum = typeof AuditLogActionEnum[keyof typeof AuditLogActionEnum];
export const AuditLogHttpMethodEnum = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE',
    Create: 'CREATE'
} as const;

export type AuditLogHttpMethodEnum = typeof AuditLogHttpMethodEnum[keyof typeof AuditLogHttpMethodEnum];
export const AuditLogResultEnum = {
    Succeeded: 'Succeeded',
    Failed: 'Failed'
} as const;

export type AuditLogResultEnum = typeof AuditLogResultEnum[keyof typeof AuditLogResultEnum];

/**
 * 
 * @export
 * @interface GetAuditLogs200Response
 */
export interface GetAuditLogs200Response {
    /**
     * 
     * @type {number}
     * @memberof GetAuditLogs200Response
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAuditLogs200Response
     */
    'next'?: number;
    /**
     * 
     * @type {Array<AuditLog>}
     * @memberof GetAuditLogs200Response
     */
    'audit_logs'?: Array<AuditLog>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}

/**
 * AuditLogsApi - axios parameter creator
 * @export
 */
export const AuditLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download audit logs as a file, based on query params filter
         * @summary Download audit logs
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {'CSV' | 'JSON'} [fileType] 
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuditLogs: async (start: string, end: string, fileType?: 'CSV' | 'JSON', numberOfSamples?: number, filterBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('downloadAuditLogs', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('downloadAuditLogs', 'end', end)
            const localVarPath = `/api/v1/audit/log/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get audit logs based on query params filter
         * @summary Get audit logs
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id'} [sortBy] Sort results by a parameters.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs: async (start: string, end: string, offset?: number, numberOfSamples?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id', filterBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getAuditLogs', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getAuditLogs', 'end', end)
            const localVarPath = `/api/v1/audit/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogsApi - functional programming interface
 * @export
 */
export const AuditLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Download audit logs as a file, based on query params filter
         * @summary Download audit logs
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {'CSV' | 'JSON'} [fileType] 
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAuditLogs(start: string, end: string, fileType?: 'CSV' | 'JSON', numberOfSamples?: number, filterBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAuditLogs(start, end, fileType, numberOfSamples, filterBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get audit logs based on query params filter
         * @summary Get audit logs
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id'} [sortBy] Sort results by a parameters.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditLogs(start: string, end: string, offset?: number, numberOfSamples?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id', filterBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditLogs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditLogs(start, end, offset, numberOfSamples, sortOrder, sortBy, filterBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogsApi - factory interface
 * @export
 */
export const AuditLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogsApiFp(configuration)
    return {
        /**
         * Download audit logs as a file, based on query params filter
         * @summary Download audit logs
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {'CSV' | 'JSON'} [fileType] 
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuditLogs(start: string, end: string, fileType?: 'CSV' | 'JSON', numberOfSamples?: number, filterBy?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.downloadAuditLogs(start, end, fileType, numberOfSamples, filterBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get audit logs based on query params filter
         * @summary Get audit logs
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id'} [sortBy] Sort results by a parameters.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs(start: string, end: string, offset?: number, numberOfSamples?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id', filterBy?: Array<string>, options?: any): AxiosPromise<GetAuditLogs200Response> {
            return localVarFp.getAuditLogs(start, end, offset, numberOfSamples, sortOrder, sortBy, filterBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogsApi - object-oriented interface
 * @export
 * @class AuditLogsApi
 * @extends {BaseAPI}
 */
export class AuditLogsApi extends BaseAPI {
    /**
     * Download audit logs as a file, based on query params filter
     * @summary Download audit logs
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {'CSV' | 'JSON'} [fileType] 
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public downloadAuditLogs(start: string, end: string, fileType?: 'CSV' | 'JSON', numberOfSamples?: number, filterBy?: Array<string>, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).downloadAuditLogs(start, end, fileType, numberOfSamples, filterBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get audit logs based on query params filter
     * @summary Get audit logs
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id'} [sortBy] Sort results by a parameters.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public getAuditLogs(start: string, end: string, offset?: number, numberOfSamples?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'timestamp' | 'subject' | 'subject_type' | 'source_ip' | 'action' | 'http_method' | 'result' | 'url' | 'entity_type' | 'entity_name' | 'entity_id', filterBy?: Array<string>, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).getAuditLogs(start, end, offset, numberOfSamples, sortOrder, sortBy, filterBy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


