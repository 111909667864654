import {
  EColumnFilterType,
  EDateFilterOperator,
  EFilterOperator,
  type IAdvancedFilterModel,
} from "@/models/filter.model";
import type { ISelectOption } from "@/models/global.model";
import { stringUtil } from "@/utils/string.util/string.util";

export const filterUtil = {
  getAdvancedFilterModelByType,
  getEqualsFilterString,
  getContainsFilterString,
  parseEnumToSelectOptions,
};

function getAdvancedFilterModelByType(filterType: EColumnFilterType): Partial<IAdvancedFilterModel> {
  switch (filterType) {
    case EColumnFilterType.FreeText:
      return {
        term: "",
        value: EFilterOperator.Contains,
      };

    case EColumnFilterType.EnumBased:
      return {
        selectOptions: [],
        selectedValues: [],
      };

    case EColumnFilterType.Date:
      return {
        value: EDateFilterOperator.Before,
        date: "",
      };

    case EColumnFilterType.Numeric:
      return {
        minValue: 0,
        maxValue: 0,
        operator: EFilterOperator.GreaterThanOrEqual,
      };

    default:
      throw new Error(`Unknown filter type: ${filterType}`);
  }
}

function getEqualsFilterString(field: string, value: string | number): string {
  return `${field}${EFilterOperator.Equals}${value}`;
}

function getContainsFilterString(field: string, value: string): string {
  return `${field}${EFilterOperator.Contains}${value}`;
}

function parseEnumToSelectOptions(enumObject: Record<string, string>): ISelectOption[] {
  return Object.keys(enumObject).map((key) => ({
    label: stringUtil.capitalizeString(enumObject[key]),
    value: key,
  }));
}
