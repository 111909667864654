<template>
  <runai-expansion-item
    class="installation-section"
    label="Installation instructions"
    default-opened
    :section-invalid="false"
  >
    <div class="q-pb-md">
      Before installing Run:ai cluster, read the
      <a href="https://docs.run.ai/latest/admin/runai-setup/cluster-setup/cluster-prerequisites/" target="_blank"
        >prerequisites</a
      >
      and install the relevant components.
    </div>
    <div class="dashed-seperator"></div>
    <div class="q-my-md">
      The following steps require a computer with access to the Kubernetes cluster and
      <a href="https://helm.sh/docs/intro/install/" target="_blank">Helm</a> installed
    </div>
    <!-- CERTIFICATE -->
    <installation-command-box v-if="showCertificateInstructions">
      <template #top
        >Set a trusted certificate for the domain name
        <runai-tooltip tooltip-text="" tooltip-position="right" width="479px">
          <template #content>
            <div>The cluster URL provided in the previous step must be trusted by a certificate authority.</div>
            <div>To create a Kubernetes secret for storing the certificate:</div>
            <ul class="list q-ma-xs">
              <li>● Replace 'fullchain.pem' with the public key</li>
              <li>● Replace 'private.pem' with the private key</li>
              <li>● Run the command in the terminal</li>
            </ul>
          </template>
        </runai-tooltip>
      </template>
      <template #commands>
        kubectl create ns runai
        <br />
        kubectl create secret tls runai-cluster-domain-tls-secret -n runai --cert /path/to/fullchain.pem --key
        /path/to/private.pem
      </template>
    </installation-command-box>

    <!-- HELM -->
    <template v-if="showHelmInstructions">
      <installation-command-box :max-height="60">
        <template #top>Download the Run:ai Helm values file by running the following command:</template>

        <template #commands>
          curl '{{ currentURL }}/v1/k8s/clusters/{{ createdCluster?.uuid }}/installfile?cloud={{ k8sDist
          }}{{ clusterURL }}' --header 'Authorization: Bearer {{ authBearer }}' > runai-{{
            selectedInstallationModel.name
          }}.yaml
        </template>

        <template #bottom>
          <div class="italic">
            Optional: To customize the cluster installation,
            <a :href="helmLink" target="_blank"> edit the Helm values file</a>
          </div>
        </template>
      </installation-command-box>
    </template>

    <!-- RUN COMMAND -->
    <installation-command-box>
      <template #top>Run the following command:</template>
      <template v-if="showOptionalCustomizeInstallation" #bottom>
        <div class="italic">
          Optional: To customize the cluster installation, see the
          <a href="https://docs.run.ai/latest/admin/runai-setup/cluster-setup/customize-cluster-install/" target="_blank"
            >installation guide</a
          >
        </div>
      </template>

      <template #commands v-if="hasClusterInstructionsAsApi">
        {{ installationInstructions }}
      </template>
      <template #commands v-else>
        <template v-if="isHigherClusterVersion || isLoadVersionsFailed">
          helm repo add {{ repositoryName }} {{ storageURL }}<br />
          helm repo update<br />
          helm upgrade -i runai-cluster {{ repositoryName }}/runai-cluster -n runai \<br />
          --set controlPlane.url={{ currentUrlNewInstallation }} \<br />
          --set controlPlane.clientSecret={{ clientSecret }} \<br />
          --set cluster.uid={{ createdCluster?.uuid }} \<br />
          --set cluster.url={{ clusterUrlNewInstallation }}
          <span v-if="!isLoadVersionsFailed">--version={{ getClusterVersion }}</span
          ><span v-else-if="defaultVersion">--version={{ defaultVersion }}</span> --create-namespace
        </template>
        <template v-else>
          helm repo add {{ repositoryName }} {{ storageURL }}
          <br />
          helm repo update <br />
          helm upgrade -i runai-cluster {{ repositoryName }}/runai-cluster -n runai -f runai-{{
            selectedInstallationModel.name
          }}.yaml {{ isStagingEnv ? "--devel" : "" }} --create-namespace --version={{ getClusterVersion }}
        </template>
      </template>
    </installation-command-box>
  </runai-expansion-item>
</template>
<script lang="ts">
// cmps
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { InstallationCommandBox } from "./installation-command-box";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { clusterK8SDistributionOptions } from "@/models/cluster.model";
import type { IClusterInstalltionModel } from "@/models/cluster.model";
// types
import type { PropType } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import { useAppStore } from "@/stores/app.store";
import { defineComponent } from "vue";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { applicationsService } from "@/services/control-plane/applications.service/applications.service";
import type { ClusterControlPlaneConfigAgentClient, DisplayedCluster } from "@/swagger-models/cluster-service-client";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    InstallationCommandBox,
    RunaiTooltip,
  },
  props: {
    createdCluster: {
      type: [Object, null] as PropType<DisplayedCluster | null>,
      required: true,
    },
    showOptionalCustomizeInstallation: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    showCertificateInstructions: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isHigherClusterVersion: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isMultiTenant: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    selectedInstallationModel: {
      type: Object as PropType<IClusterInstalltionModel>,
      required: true,
    },
    versionsMap: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    isStagingEnv: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isLoadVersionsFailed: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      appStore: useAppStore(),
      installationInstructions: "" as string | undefined,
      clientSecret: "" as string,
    };
  },
  async created() {
    this.installationInstructions = await this.getInstallationInstructions();
    const clusterControlPlaneConfigAgentClient: ClusterControlPlaneConfigAgentClient =
      await applicationsService.getRunAiInstallerAppClient();
    this.clientSecret = clusterControlPlaneConfigAgentClient?.secret || "";
  },
  computed: {
    helmLink(): string {
      return "https://docs.run.ai/latest/admin/runai-setup/cluster-setup/customize-cluster-install/";
    },
    showHelmInstructions(): boolean {
      return !this.isHigherClusterVersion;
    },
    currentUrlNewInstallation(): string {
      return this.currentURL.replace(/^https?:\/\//, "");
    },
    clusterUrlNewInstallation(): string {
      const clusterURL: string = this.selectedInstallationModel.prerequisites.clusterUrl;
      return (clusterURL ? clusterURL : this.currentURL).replace(/^https?:\/\//, "");
    },
    clusterURL(): string {
      return this.selectedInstallationModel.prerequisites.clusterUrl
        ? `&clusterip=${this.selectedInstallationModel.prerequisites.clusterUrl}`
        : "";
    },
    k8sDist(): string {
      return (
        clusterK8SDistributionOptions.find(
          (dist) => dist.id === this.selectedInstallationModel.prerequisites.k8sDistribution,
        )?.value || ""
      );
    },
    currentURL(): string {
      return window.location.origin;
    },
    authBearer(): string {
      return this.authStore.accessToken;
    },
    defaultVersion(): string {
      return this.selectedInstallationModel.version ? this.selectedInstallationModel.version : "";
    },
    getClusterVersion(): string {
      if (!this.selectedInstallationModel.version) return "";
      return this.versionsMap[this.selectedInstallationModel.version];
    },
    currentVersion(): number {
      return Number(this.selectedInstallationModel.version || 0);
    },
    isBiggerOrEqualTo2_17_0(): boolean {
      return this.currentVersion >= 2.17;
    },
    hasClusterInstructionsAsApi(): boolean {
      return this.currentVersion >= 2.16;
    },
    storageURL(): string {
      if (this.isBiggerOrEqualTo2_17_0) {
        return this.isStagingEnv
          ? "https://runai.jfrog.io/artifactory/api/helm/op-charts-staging"
          : "https://runai.jfrog.io/artifactory/api/helm/op-charts-prod";
      } else {
        return this.isStagingEnv
          ? "https://run-ai-charts-staging.storage.googleapis.com"
          : "https://run-ai-charts.storage.googleapis.com";
      }
    },
    repositoryName(): string {
      return this.isStagingEnv ? "runai-staging" : "runai";
    },
  },
  methods: {
    async getInstallationInstructions(): Promise<string | undefined> {
      const response = await clusterService.getClusterInstallationInfo(
        this.createdCluster?.uuid as string,
        String(this.getClusterVersion),
        this.selectedInstallationModel.prerequisites.clusterUrl,
      );
      return response ? response.data.installationStr : undefined;
    },
  },
});
</script>
<style lang="scss">
.installation-section {
  .dev-mode-span {
    color: blue;
  }
  .dashed-seperator {
    border: 1px dashed $black-12;
  }
  .list {
    li {
      display: list-item;
      list-style-type: circle;
    }
  }
}
</style>
