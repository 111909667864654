import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";

import { CLUSTER_COLUMN_FILTER_NAME, EColumnFilterType } from "@/models/filter.model";
import { type Nodepool, NodepoolPhase, NodepoolSortFilterFields } from "@/swagger-models/cluster-service-client";
import { nodePoolUtil } from "@/utils/node-pool.util/node-pool.util";
import { tableFormatUtil } from "@/utils/table-format.util/table-format.util";
import { memoryFormat } from "@/utils/format.util";

export interface IAdditionalNodePoolTableFields {
  totalGpus?: number;
  totalGpuMemoryBytes?: number;
  allocatedGpus?: number;
  allocatedGpuMemory?: number;
  totalCpuCores?: number;
  totalCpuMemoryBytes?: number;
  allocatedCpuCores?: number;
  allocatedCpuMemoryBytes?: number;
}

export interface INodePoolTable extends Nodepool {
  additionalFields?: IAdditionalNodePoolTableFields;
}

const _formatMemoryValue = (val: number | undefined): string =>
  memoryFormat(tableFormatUtil.getFormattedNumberOrDefault(val));

export const nodePoolV2ColumnName = {
  name: NodepoolSortFilterFields.Name,
  phase: NodepoolSortFilterFields.Phase,
  cluster: CLUSTER_COLUMN_FILTER_NAME,
  labelKey: "labelKey",
  labelValue: "labelValue",
  nodes: "nodes",
  gpuPlacementStrategy: "gpuPlacementStrategy",
  cpuPlacementStrategy: "cpuPlacementStrategy",
  updatedAt: NodepoolSortFilterFields.UpdatedAt,
  createdAt: NodepoolSortFilterFields.CreatedAt,
  workloads: "workloads",
  overProvisioningRatio: "overProvisioningRatio",
  totalGpus: "totalGpus",
  totalGpuMemoryBytes: "totalGpuMemoryBytes",
  allocatedGpus: "allocatedGpus",
  totalCpuCores: "totalCpuCores",
  totalCpuMemoryBytes: "totalCpuMemoryBytes",
  allocatedCpuCores: "allocatedCpuCores",
  allocatedCpuMemoryBytes: "allocatedCpuMemoryBytes",
};

enum ENodePoolV2ColumnLabel {
  Name = "Node pool",
  Phase = "Status",
  Cluster = "Cluster",
  LabelKey = "Label key",
  LabelValue = "Label value",
  Nodes = "Node(s)",
  GpuPlacementStrategy = "GPU placement strategy",
  CpuPlacementStrategy = "CPU placement strategy",
  UpdatedAt = "Last updated",
  CreatedAt = "Creation time",
  Workloads = "Workload(s)",
  OverProvisioningRatio = "GPU resource optimization ratio",
  TotalGpus = "Total GPU devices",
  TotalGpuMemoryBytes = "Total GPU memory",
  AllocatedGpus = "Allocated GPUs",
  TotalCpuCores = "Total CPU (cores)",
  TotalCpuMemoryBytes = "Total CPU memory",
  AllocatedCpuCores = "Allocated CPU (cores)",
  AllocatedCpuMemoryBytes = "Allocated CPU memory",
}

export const allNodePoolsV2ColumnsMap: Record<keyof typeof nodePoolV2ColumnName, ITableColumn> = {
  /*** clusterServiceApi.getNodepools columns ***/
  name: {
    name: nodePoolV2ColumnName.name,
    label: ENodePoolV2ColumnLabel.Name,
    field: (nodePool: INodePoolTable): string => nodePool.name,
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  cluster: {
    name: nodePoolV2ColumnName.cluster,
    label: ENodePoolV2ColumnLabel.Cluster,
    field: (nodePool: INodePoolTable): string => nodePool.clusterId,
    sortable: false,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
  },
  phase: {
    name: nodePoolV2ColumnName.phase,
    label: ENodePoolV2ColumnLabel.Phase,
    field: (nodePool: INodePoolTable): INodePoolTable => nodePool,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (nodePool: INodePoolTable): IStatusColOptions => nodePoolUtil.getStatusColOptions(nodePool),
    filter: {
      type: EColumnFilterType.EnumBased,
      selectOptions: Object.keys(NodepoolPhase).map((key) => ({
        label: key,
        value: key,
      })),
    },
  },
  labelKey: {
    name: nodePoolV2ColumnName.labelKey,
    label: ENodePoolV2ColumnLabel.LabelKey,
    field: (nodePool: INodePoolTable): string => nodePool.labelKey,
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  labelValue: {
    name: nodePoolV2ColumnName.labelValue,
    label: ENodePoolV2ColumnLabel.LabelValue,
    field: (nodePool: INodePoolTable): string => nodePool.labelValue,
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  nodes: {
    name: nodePoolV2ColumnName.nodes,
    label: ENodePoolV2ColumnLabel.Nodes,
    field: (nodePool: INodePoolTable): string[] => nodePool.status?.nodes || [],
    sortable: false,
    align: "left",
    hideFilter: true,
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "nodes-clicked" },
  },
  gpuPlacementStrategy: {
    name: nodePoolV2ColumnName.gpuPlacementStrategy,
    label: ENodePoolV2ColumnLabel.GpuPlacementStrategy,
    field: (nodePool: INodePoolTable): string => nodePool?.placementStrategy?.gpu || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  cpuPlacementStrategy: {
    name: nodePoolV2ColumnName.cpuPlacementStrategy,
    label: ENodePoolV2ColumnLabel.CpuPlacementStrategy,
    field: (nodePool: INodePoolTable): string => nodePool?.placementStrategy?.cpu || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  workloads: {
    name: nodePoolV2ColumnName.workloads,
    label: ENodePoolV2ColumnLabel.Workloads,
    field: () => "View",
    sortable: false,
    align: "left",
    display: false,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "workloads-clicked" },
    hideFilter: true,
  },
  overProvisioningRatio: {
    name: nodePoolV2ColumnName.overProvisioningRatio,
    label: ENodePoolV2ColumnLabel.OverProvisioningRatio,
    field: (nodePool: INodePoolTable): string =>
      (nodePool.overProvisioningRatio && nodePoolUtil.getNodePoolOprDisplayValue(nodePool.overProvisioningRatio)) || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  updatedAt: {
    name: nodePoolV2ColumnName.updatedAt,
    label: ENodePoolV2ColumnLabel.UpdatedAt,
    field: (nodePool: INodePoolTable): string => tableFormatUtil.getFormattedDateOrDefault(nodePool.updatedAt),
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  createdAt: {
    name: nodePoolV2ColumnName.createdAt,
    label: ENodePoolV2ColumnLabel.CreatedAt,
    field: (nodePool: INodePoolTable): string => tableFormatUtil.getFormattedDateOrDefault(nodePool.createdAt),
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  /*** clusterServiceApi.getNodeTelemetries columns ***/
  totalGpus: {
    name: nodePoolV2ColumnName.totalGpus,
    label: ENodePoolV2ColumnLabel.TotalGpus,
    field: (nodePool: INodePoolTable): string =>
      tableFormatUtil.getFormattedNumberOrDefault(nodePool.additionalFields?.totalGpus),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  totalGpuMemoryBytes: {
    name: nodePoolV2ColumnName.totalGpuMemoryBytes,
    label: ENodePoolV2ColumnLabel.TotalGpuMemoryBytes,
    field: (nodePool: INodePoolTable): string => _formatMemoryValue(nodePool.additionalFields?.totalGpuMemoryBytes),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedGpus: {
    name: nodePoolV2ColumnName.allocatedGpus,
    label: ENodePoolV2ColumnLabel.AllocatedGpus,
    field: (nodePool: INodePoolTable): string =>
      tableFormatUtil.getFormattedNumberOrDefault(nodePool.additionalFields?.allocatedGpus),
    sortable: true,
    align: "left",
    hideFilter: true,
  },
  totalCpuCores: {
    name: nodePoolV2ColumnName.totalCpuCores,
    label: ENodePoolV2ColumnLabel.TotalCpuCores,
    field: (nodePool: INodePoolTable): string =>
      tableFormatUtil.getFormattedNumberOrDefault(nodePool.additionalFields?.totalCpuCores),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  totalCpuMemoryBytes: {
    name: nodePoolV2ColumnName.totalCpuMemoryBytes,
    label: ENodePoolV2ColumnLabel.TotalCpuMemoryBytes,
    field: (nodePool: INodePoolTable): string => _formatMemoryValue(nodePool.additionalFields?.totalCpuMemoryBytes),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedCpuCores: {
    name: nodePoolV2ColumnName.allocatedCpuCores,
    label: ENodePoolV2ColumnLabel.AllocatedCpuCores,
    field: (nodePool: INodePoolTable): string =>
      tableFormatUtil.getFormattedNumberOrDefault(nodePool.additionalFields?.allocatedCpuCores),
    sortable: true,
    align: "left",
    hideFilter: true,
  },
  allocatedCpuMemoryBytes: {
    name: nodePoolV2ColumnName.allocatedCpuMemoryBytes,
    label: ENodePoolV2ColumnLabel.AllocatedCpuMemoryBytes,
    field: (nodePool: INodePoolTable): string => _formatMemoryValue(nodePool.additionalFields?.allocatedCpuMemoryBytes),
    sortable: true,
    align: "left",
    hideFilter: true,
  },
};

export const nodePoolV2IndexColumns: Array<ITableColumn> = [
  { ...allNodePoolsV2ColumnsMap.name, display: true, mandatory: true },
  { ...allNodePoolsV2ColumnsMap.phase, display: true },
  { ...allNodePoolsV2ColumnsMap.cluster, display: false },
  { ...allNodePoolsV2ColumnsMap.labelKey, display: true },
  { ...allNodePoolsV2ColumnsMap.labelValue, display: true },
  { ...allNodePoolsV2ColumnsMap.nodes, display: true },
  { ...allNodePoolsV2ColumnsMap.totalGpus, display: true },
  { ...allNodePoolsV2ColumnsMap.totalGpuMemoryBytes, display: true },
  { ...allNodePoolsV2ColumnsMap.allocatedGpus, display: true },
  { ...allNodePoolsV2ColumnsMap.overProvisioningRatio, display: false },
  { ...allNodePoolsV2ColumnsMap.totalCpuCores, display: true },
  { ...allNodePoolsV2ColumnsMap.totalCpuMemoryBytes, display: true },
  { ...allNodePoolsV2ColumnsMap.allocatedCpuCores, display: true },
  { ...allNodePoolsV2ColumnsMap.allocatedCpuMemoryBytes, display: true },
  { ...allNodePoolsV2ColumnsMap.gpuPlacementStrategy, display: false },
  { ...allNodePoolsV2ColumnsMap.cpuPlacementStrategy, display: false },
  { ...allNodePoolsV2ColumnsMap.updatedAt, display: false },
  { ...allNodePoolsV2ColumnsMap.createdAt, display: true },
  { ...allNodePoolsV2ColumnsMap.workloads, display: true },
];

export const nodePoolsV2DependentColumns = {
  nodes: new Set([nodePoolV2ColumnName.nodes]), //only shown if the user has the permission to view nodes
  overProvisioningRatio: new Set([nodePoolV2ColumnName.overProvisioningRatio]), //only shown when ft is on
  cluster: new Set([nodePoolV2ColumnName.cluster]), //only shown when there more than one cluster
};
