import type { IResourceQuota } from "@/models/project.model";
import {
  EOverQuotaLabel,
  EOverQuotaPriorityLabel,
  type INodePoolIsOverQuota,
  type INodePoolResourcesSum,
} from "@/models/resource.model";
import {
  CPU_VALUE_FACTOR,
  DEFAULT_NODE_POOL_NAME,
  EMemoryUnit,
  EResourceState,
  EResourceType,
  type NodePoolAllocatedNonPreemptibleSumRecord,
} from "@/models/resource.model";
import { deepCopy } from "@/utils/common.util";
import type { QuotaStatusNodePool, Resources } from "@/swagger-models/org-unit-service-client";

const MIB_TO_MB_FACTOR = 1.048576;
const MIB_TO_GB_FACTOR = 953.67431640625;
const MEMORY_SIZE_THRESHOLD = 9536.7431640625;
const BYTE_TO_MIB_FACTOR = 1048576;
export const resourceUtil = {
  sumOfResourcesByType,
  isResourcesUnlimited,
  getResourceDisplayValue,
  getResourcesDisplayValue,
  convertMemoryValue,
  fromGbToMib,
  fromMbToMib,
  fromMibToMemoryUnit,
  getOverQuotaPriorityKeyByValue,
  getOverQuotaKeyByValue,
  isResourceExceedsQuota,
  isResourceUnlimited,
  sortNodePools,
  computeNodePoolResourceExceedsQuota,
  fromBytesToMiB,
  allocatedNonPreemptibleResources,
  isResourceUnderAllocatedNonPreemptible,
  getNodePoolResourceOverQuotaWeightDisplayedValue,
};

function sumOfResourcesByType(resources: Resources[], resourceType: EResourceType): number {
  return resources.reduce((sum, nodePool) => {
    const deserved = nodePool[resourceType]?.deserved || 0;
    return sum + (deserved > 0 ? deserved : 0);
  }, 0);
}

function isResourcesUnlimited(resources: Resources[], type: EResourceType): boolean {
  return resources.every((nodePoolResource: Resources) => nodePoolResource[type]?.deserved === null);
}

function getResourcesDisplayValue(resources: Resources[], resourceType: EResourceType): string {
  if (
    (resourceType === EResourceType.CPU || resourceType === EResourceType.MEMORY) &&
    isResourcesUnlimited(resources, resourceType)
  ) {
    return EResourceState.Unlimited;
  }
  const sumOfResources = sumOfResourcesByType(resources, resourceType);
  if (resourceType === EResourceType.CPU) {
    return (sumOfResourcesByType(resources, EResourceType.CPU) / CPU_VALUE_FACTOR).toFixed(2);
  } else if (resourceType === EResourceType.MEMORY) {
    const convertedValue = convertMemoryValue(sumOfResources);
    return `${convertedValue.value.toFixed(2)} ${convertedValue.selectedUnit}`;
  }

  return sumOfResources.toFixed(2);
}
function getResourceDisplayValue(value: number | null | undefined, resourceType: EResourceType): string {
  if (value === null || value === undefined) {
    if (resourceType === EResourceType.CPU || resourceType === EResourceType.MEMORY) {
      return EResourceState.Unlimited;
    }
    return "0.00";
  }

  if (resourceType === EResourceType.CPU) {
    return (value / CPU_VALUE_FACTOR).toFixed(2);
  } else if (resourceType === EResourceType.MEMORY) {
    const convertedValue = convertMemoryValue(value);
    return `${convertedValue.value.toFixed(2)} ${convertedValue.selectedUnit}`;
  }
  if (resourceType === EResourceType.GPU) {
    if (value < 0) {
      return "-";
    }
  }
  return value.toFixed(2);
}

function fromMibToMb(mib: number): number {
  return mib * MIB_TO_MB_FACTOR;
}

function fromMibToGb(mib: number): number {
  return mib / MIB_TO_GB_FACTOR;
}

function fromMibToMemoryUnit(unit: EMemoryUnit, mib: number): number {
  if (unit === EMemoryUnit.MB) {
    return fromMibToMb(mib);
  }
  return fromMibToGb(mib);
}

function fromMbToMib(mib: number): number {
  return mib / MIB_TO_MB_FACTOR;
}

function fromGbToMib(mib: number): number {
  return mib * MIB_TO_GB_FACTOR;
}

function fromBytesToMiB(bytes: number): number {
  return bytes / BYTE_TO_MIB_FACTOR;
}

function convertMemoryValue(value: number): { value: number; selectedUnit: EMemoryUnit } {
  const beforeDecimalPointPart = Math.trunc(value);
  if (beforeDecimalPointPart > MEMORY_SIZE_THRESHOLD) {
    return {
      value: fromMibToGb(value),
      selectedUnit: EMemoryUnit.GB,
    };
  } else {
    return {
      value: fromMibToMb(value),
      selectedUnit: EMemoryUnit.MB,
    };
  }
}

function getOverQuotaPriorityKeyByValue(value: number | null | undefined): string {
  switch (value) {
    case -1:
      return EOverQuotaPriorityLabel.None;
    case 0:
      return EOverQuotaPriorityLabel.Lowest;
    case 1:
      return EOverQuotaPriorityLabel.Low;
    case 2:
      return EOverQuotaPriorityLabel.Medium;
    case 3:
      return EOverQuotaPriorityLabel.High;
    default:
      return EOverQuotaPriorityLabel.Medium;
  }
}

function getOverQuotaKeyByValue(value: number | null | undefined): string {
  switch (value) {
    case 0:
      return EOverQuotaLabel.Disabled;
    case 2:
      return EOverQuotaLabel.Enabled;
    default:
      return EOverQuotaLabel.Enabled;
  }
}

function isResourceExceedsQuota(
  projectsDeservedResourceWithCurrentInput: number,
  departmentDeservedResource: number,
  departmentDeservedResourceIsUnlimited = false,
): boolean {
  if (departmentDeservedResourceIsUnlimited) {
    return false;
  }
  return projectsDeservedResourceWithCurrentInput > departmentDeservedResource;
}

function isResourceUnderAllocatedNonPreemptible(val: number, allocatedNonPreemptibleResource: number): boolean {
  return val < allocatedNonPreemptibleResource;
}

function isResourceUnlimited(resourceValue?: number | null): boolean {
  return resourceValue === null || resourceValue === undefined;
}

function sortNodePools(resources: Resources[]): Resources[] {
  const sortedNodePools = deepCopy(resources).sort((resourcesA: Resources, resourcesB: Resources) =>
    (resourcesA.nodePool?.name || "").localeCompare(resourcesB.nodePool?.name || ""),
  );

  const defaultNodePool = sortedNodePools.find((np) => np.nodePool && np.nodePool.name === DEFAULT_NODE_POOL_NAME);
  if (!defaultNodePool) return sortedNodePools;
  const sortedNodePoolsWithoutDefault = sortedNodePools.filter(
    (np) => np.nodePool && np.nodePool.name !== DEFAULT_NODE_POOL_NAME,
  );
  return [defaultNodePool, ...sortedNodePoolsWithoutDefault];
}

function getNodePoolResourceOverQuotaWeightDisplayedValue(
  resourceQuota: IResourceQuota,
  isOverQuotaPriorityEnabled: boolean,
): string {
  if (isOverQuotaPriorityEnabled) {
    return resourceUtil.getOverQuotaPriorityKeyByValue(resourceQuota?.overQuotaWeight);
  } else {
    return resourceUtil.getOverQuotaKeyByValue(resourceQuota?.overQuotaWeight);
  }
}

function computeNodePoolResourceExceedsQuota(
  projectsResources: INodePoolResourcesSum,
  departmentResources: INodePoolResourcesSum,
): INodePoolIsOverQuota {
  const nodePoolIsOverQuota = { gpu: false, cpu: false, memory: false } as INodePoolIsOverQuota;
  if (!departmentResources || !projectsResources) return nodePoolIsOverQuota;
  nodePoolIsOverQuota.gpu = projectsResources.gpu > departmentResources.gpu;

  if (departmentResources.cpu === null || departmentResources.cpu === undefined) {
    nodePoolIsOverQuota.cpu = false;
  } else {
    nodePoolIsOverQuota.cpu = +projectsResources.cpu > departmentResources.cpu;
  }

  if (departmentResources.memory === null || departmentResources.memory === undefined) {
    nodePoolIsOverQuota.memory = false;
  } else {
    //memory in DB is in MIB and in UI is in MG/GB so ignore the fractional digits
    nodePoolIsOverQuota.memory = Math.trunc(+projectsResources.memory) > Math.round(departmentResources.memory);
  }

  return nodePoolIsOverQuota;
}

function allocatedNonPreemptibleResources(
  nodePoolQuotaStatuses: Array<QuotaStatusNodePool>,
): NodePoolAllocatedNonPreemptibleSumRecord {
  return nodePoolQuotaStatuses.reduce((result, status) => {
    result[status.nodePoolName as string] = {
      cpu: status.allocatedNonPreemptible?.cpu || 0,
      gpu: status.allocatedNonPreemptible?.gpu || 0,
      memory: status.allocatedNonPreemptible?.memory || 0,
    };
    return result;
  }, {} as NodePoolAllocatedNonPreemptibleSumRecord);
}
