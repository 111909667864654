<template>
  <section class="serving-endpoint-access">
    <div class="serving-endpoint-access-title q-mb-sm">
      {{ title }}
      <runai-tooltip v-if="titleTooltip" :tooltip-text="titleTooltip" tooltip-position="right" width="520px" />
    </div>

    <section class="row">
      <span class="row col-8 gap-20" v-if="protocol || port">
        <span v-if="protocol" class="col-4 connection-protocol">
          <q-input
            aid="connection-protocol"
            label="Connection protocol"
            stack-label
            no-error-icon
            :model-value="protocol"
            disable
          >
          </q-input>
          <q-tooltip>The connection protocol and the container port are defined within the environment</q-tooltip>
        </span>
        <span v-if="port" class="col-3 container-port">
          <q-input aid="container-port" label="Container port" stack-label no-error-icon :model-value="port" disable>
          </q-input>
          <q-tooltip>The connection protocol and the container port are defined within the environment</q-tooltip>
        </span>
      </span>
      <span class="col-4 connection-access">
        <connection-access
          :access="access"
          :tool-name="'Endpoint'"
          @save="onAccessChanged"
          enable-public-access
          connection-type="endpoint"
        />
      </span>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { ConnectionAccess } from "@/components/environment/connection-access";

// models
import { type IConnectionAccess } from "@/components/environment/connection-access";

export default defineComponent({
  components: {
    RunaiTooltip,
    ConnectionAccess,
  },
  emits: ["access-changed"],
  props: {
    access: {
      type: Object as PropType<IConnectionAccess>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    titleTooltip: {
      type: String as PropType<string>,
      required: false,
    },
    protocol: {
      type: String as PropType<string>,
      required: false,
    },
    port: {
      type: String as PropType<string>,
      required: false,
    },
  },
  methods: {
    onAccessChanged(access: IConnectionAccess): void {
      this.$emit("access-changed", access);
    },
  },
});
</script>
<style lang="scss" scoped></style>
