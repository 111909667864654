import type { ISelectOption } from "@/models/global.model";

export interface IThresholdMetricOption {
  id: string;
  name: string;
}

export interface IAutoScaleData {
  minReplicas: number;
  maxReplicas: number;
  thresholdMetric: string | undefined;
  thresholdValue: number | undefined;
  scaleToZeroRetentionSeconds: number | undefined;
}

export const MIN_REPLICAS = 1;
export const MAX_REPLICAS_ALLOWED = 10000;
export const DEFAULT_THRESHOLD_VALUE = 100;
export const NEVER_SCALE_DOWN_ID = "never";
export const CUSTOM_SCALE_DOWN_ID = "custom";
export const MIN_REPLICAS_WHEN_SCALE_TO_ZERO_SELECTED = 0;
export const SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE = 1;
export const METRIC_THROUGHPUT = "throughput";
export const METRIC_CONCURRENCY = "concurrency";
export const METRIC_LATENCY = "latency";
export const THRESHOLD_DEFAULT_METRIC = METRIC_THROUGHPUT;

export const scaleDownOptions: Array<ISelectOption> = [
  {
    value: NEVER_SCALE_DOWN_ID,
    label: "Never",
  },
  {
    value: "60",
    label: "After 1 minute of inactivity",
  },
  {
    value: "300",
    label: "After 5 minutes of inactivity",
  },
  {
    value: "900",
    label: "After 15 minutes of inactivity",
  },
  {
    value: "1800",
    label: "After 30 minutes of inactivity",
  },
  {
    value: CUSTOM_SCALE_DOWN_ID,
    label: "Custom",
  },
];

export const thresholdMetricOptions: Array<IThresholdMetricOption> = [
  {
    id: METRIC_LATENCY,
    name: "Latency (millisecond)",
  },
  {
    id: METRIC_THROUGHPUT,
    name: "Throughput (Requests/sec)",
  },
  {
    id: METRIC_CONCURRENCY,
    name: "Concurrency (Requests)",
  },
];
