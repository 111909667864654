<template>
  <div class="runai-section-title q-mb-sm" :class="{ 'no-margin': flat }">
    <span class="q-mr-xs">{{ title }}</span>
    <runai-tooltip v-if="tooltipText" :tooltip-text="tooltipText" :width="width" :tooltip-position="tooltipPosition" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";

export default defineComponent({
  name: "runai-section-title",
  components: {
    RunaiTooltip,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    tooltipText: {
      type: String as PropType<string>,
      required: false,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: "300px",
    },
    tooltipPosition: {
      type: String as PropType<string>,
      required: false,
      default: "right",
    },
    flat: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
});
</script>
