/* tslint:disable */
/* eslint-disable */
/**
 * Authorization
 * An API for managing RunAi authorization objects and perform as the RunAi authorization layer.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessRule
 */
export interface AccessRule {
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof AccessRule
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {number}
     * @memberof AccessRule
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'scopeId': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof AccessRule
     */
    'scopeType': ScopeType;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AccessRule
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'scopeName': string;
    /**
     * 
     * @type {number}
     * @memberof AccessRule
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof AccessRule
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'createdBy': string;
}
/**
 * 
 * @export
 * @interface AccessRuleById
 */
export interface AccessRuleById {
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof AccessRuleById
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {number}
     * @memberof AccessRuleById
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'scopeId': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof AccessRuleById
     */
    'scopeType': ScopeType;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AccessRuleById
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'scopeName': string;
    /**
     * 
     * @type {number}
     * @memberof AccessRuleById
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof AccessRuleById
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleById
     */
    'scopePath'?: string | null;
}
/**
 * 
 * @export
 * @interface AccessRuleByIdAllOf
 */
export interface AccessRuleByIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof AccessRuleByIdAllOf
     */
    'scopePath'?: string | null;
}
/**
 * 
 * @export
 * @interface AccessRuleCreationFields
 */
export interface AccessRuleCreationFields {
    /**
     * 
     * @type {string}
     * @memberof AccessRuleCreationFields
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof AccessRuleCreationFields
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {number}
     * @memberof AccessRuleCreationFields
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleCreationFields
     */
    'scopeId': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof AccessRuleCreationFields
     */
    'scopeType': ScopeType;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof AccessRuleCreationFields
     */
    'clusterId'?: string;
}
/**
 * 
 * @export
 * @interface AccessRuleForSync
 */
export interface AccessRuleForSync {
    /**
     * Phase of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleForSync
     */
    'phase': AccessRuleForSyncPhaseEnum;
    /**
     * Message for status of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleForSync
     */
    'message': string;
    /**
     * The id of the access rule to retrieve
     * @type {number}
     * @memberof AccessRuleForSync
     */
    'accessRuleId': number;
}

export const AccessRuleForSyncPhaseEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady'
} as const;

export type AccessRuleForSyncPhaseEnum = typeof AccessRuleForSyncPhaseEnum[keyof typeof AccessRuleForSyncPhaseEnum];

/**
 * 
 * @export
 * @interface AccessRuleForSyncAllOf
 */
export interface AccessRuleForSyncAllOf {
    /**
     * The id of the access rule to retrieve
     * @type {number}
     * @memberof AccessRuleForSyncAllOf
     */
    'accessRuleId': number;
}
/**
 * 
 * @export
 * @interface AccessRuleStatusRequest
 */
export interface AccessRuleStatusRequest {
    /**
     * Phase of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleStatusRequest
     */
    'phase': AccessRuleStatusRequestPhaseEnum;
    /**
     * Message for status of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleStatusRequest
     */
    'message': string;
}

export const AccessRuleStatusRequestPhaseEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady'
} as const;

export type AccessRuleStatusRequestPhaseEnum = typeof AccessRuleStatusRequestPhaseEnum[keyof typeof AccessRuleStatusRequestPhaseEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AccessRulesSortFilterFields = {
    SubjectId: 'subjectId',
    SubjectType: 'subjectType',
    RoleId: 'roleId',
    ScopeId: 'scopeId',
    ScopeType: 'scopeType',
    RoleName: 'roleName',
    ScopeName: 'scopeName',
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    CreatedBy: 'createdBy'
} as const;

export type AccessRulesSortFilterFields = typeof AccessRulesSortFilterFields[keyof typeof AccessRulesSortFilterFields];


/**
 * 
 * @export
 * @enum {string}
 */

export const Action = {
    Create: 'create',
    Read: 'read',
    Update: 'update',
    Delete: 'delete',
    Sync: 'sync'
} as const;

export type Action = typeof Action[keyof typeof Action];


/**
 * 
 * @export
 * @enum {string}
 */

export const AllowedInternalCreateRoles = {
    SystemAdministrator: 'System administrator',
    ClusterApplication: 'Cluster application',
    InstallerApplication: 'Installer application',
    CloudOperator: 'Cloud operator'
} as const;

export type AllowedInternalCreateRoles = typeof AllowedInternalCreateRoles[keyof typeof AllowedInternalCreateRoles];


/**
 * 
 * @export
 * @interface BackendEvaluationRequest
 */
export interface BackendEvaluationRequest {
    /**
     * 
     * @type {string}
     * @memberof BackendEvaluationRequest
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {ResourceType}
     * @memberof BackendEvaluationRequest
     */
    'resourceType'?: ResourceType | null;
    /**
     * 
     * @type {Action}
     * @memberof BackendEvaluationRequest
     */
    'requestAction'?: Action | null;
}
/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface CountAccessRules200Response
 */
export interface CountAccessRules200Response {
    /**
     * 
     * @type {number}
     * @memberof CountAccessRules200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface EnrichmentDataFields
 */
export interface EnrichmentDataFields {
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDataFields
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDataFields
     */
    'scopeName': string;
}
/**
 * 
 * @export
 * @interface GetAccessRules200Response
 */
export interface GetAccessRules200Response {
    /**
     * 
     * @type {number}
     * @memberof GetAccessRules200Response
     */
    'totalRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetAccessRules200Response
     */
    'displayRecords': number;
    /**
     * 
     * @type {Array<AccessRule>}
     * @memberof GetAccessRules200Response
     */
    'accessRules': Array<AccessRule>;
}
/**
 * 
 * @export
 * @interface GetGroups200Response
 */
export interface GetGroups200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetGroups200Response
     */
    'names': Array<string>;
}
/**
 * 
 * @export
 * @interface GetPermittedScopesRequest
 */
export interface GetPermittedScopesRequest {
    /**
     * 
     * @type {ResourceType}
     * @memberof GetPermittedScopesRequest
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {string}
     * @memberof GetPermittedScopesRequest
     */
    'action'?: GetPermittedScopesRequestActionEnum;
}

export const GetPermittedScopesRequestActionEnum = {
    Create: 'create',
    Read: 'read',
    Update: 'update',
    Delete: 'delete'
} as const;

export type GetPermittedScopesRequestActionEnum = typeof GetPermittedScopesRequestActionEnum[keyof typeof GetPermittedScopesRequestActionEnum];

/**
 * 
 * @export
 * @interface InternalCreateAccessRuleRequest
 */
export interface InternalCreateAccessRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalCreateAccessRuleRequest
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof InternalCreateAccessRuleRequest
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {AllowedInternalCreateRoles}
     * @memberof InternalCreateAccessRuleRequest
     */
    'roleName': AllowedInternalCreateRoles;
    /**
     * 
     * @type {InternalCreateAccessRuleRequestScope}
     * @memberof InternalCreateAccessRuleRequest
     */
    'scope'?: InternalCreateAccessRuleRequestScope | null;
}
/**
 * 
 * @export
 * @interface InternalCreateAccessRuleRequestScope
 */
export interface InternalCreateAccessRuleRequestScope {
    /**
     * 
     * @type {string}
     * @memberof InternalCreateAccessRuleRequestScope
     */
    'id': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof InternalCreateAccessRuleRequestScope
     */
    'type': ScopeType;
    /**
     * 
     * @type {string}
     * @memberof InternalCreateAccessRuleRequestScope
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MetadataFields
 */
export interface MetadataFields {
    /**
     * 
     * @type {number}
     * @memberof MetadataFields
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof MetadataFields
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'createdBy': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {ResourceType}
     * @memberof Permission
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'displayName': string;
    /**
     * 
     * @type {ResourceTypeGroupId}
     * @memberof Permission
     */
    'groupId': ResourceTypeGroupId;
    /**
     * 
     * @type {Array<Action>}
     * @memberof Permission
     */
    'actions': Array<Action>;
}
/**
 * 
 * @export
 * @interface PermittedScopes
 */
export interface PermittedScopes {
    /**
     * 
     * @type {boolean}
     * @memberof PermittedScopes
     */
    'system'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'tenants'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PermittedScopes
     * @deprecated
     */
    'tenant'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'clusters'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'departments'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'projects'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PermittedScopesActions
 */
export interface PermittedScopesActions {
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'create': PermittedScopes;
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'read': PermittedScopes;
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'update': PermittedScopes;
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'delete': PermittedScopes;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResourceType = {
    Department: 'department',
    Tenant: 'tenant',
    Project: 'project',
    Cluster: 'cluster',
    ClusterConfig: 'cluster-config',
    Nodepools: 'nodepools',
    Nodes: 'nodes',
    Settings: 'settings',
    Users: 'users',
    Groups: 'groups',
    Apps: 'apps',
    DashboardsOverview: 'dashboards-overview',
    DashboardsAnalytics: 'dashboards-analytics',
    DashboardsConsumption: 'dashboards-consumption',
    Roles: 'roles',
    AccessRules: 'access_rules',
    Jobs: 'jobs',
    Workloads: 'workloads',
    Workspaces: 'workspaces',
    Trainings: 'trainings',
    Inferences: 'inferences',
    Environments: 'environments',
    PvcAssets: 'pvc-assets',
    GitAssets: 'git-assets',
    HostPathAssets: 'host-path-assets',
    NfsAssets: 'nfs-assets',
    S3Assets: 's3-assets',
    ComputeResources: 'compute-resources',
    Templates: 'templates',
    Credentials: 'credentials',
    EventsHistory: 'events-history',
    Policies: 'policies',
    CmVolumeAssets: 'cm-volume-assets',
    Datavolumes: 'datavolumes',
    DatavolumesSharedscopes: 'datavolumes/sharedscopes',
    SecretVolumeAssets: 'secret-volume-assets',
    StorageClassConfiguration: 'storage-class-configuration',
    PersonalApps: 'personal-apps'
} as const;

export type ResourceType = typeof ResourceType[keyof typeof ResourceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ResourceTypeGroupId = {
    Organization: 'organization',
    PhysicalResource: 'physical-resource',
    Iam: 'iam',
    Dashboard: 'dashboard',
    Workload: 'workload',
    WorkloadAsset: 'workload-asset'
} as const;

export type ResourceTypeGroupId = typeof ResourceTypeGroupId[keyof typeof ResourceTypeGroupId];


/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description': string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof Role
     */
    'permissions': Array<Permission>;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof Role
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdBy': string;
}
/**
 * May contain additional creation fields that are not updatable
 * @export
 * @interface RoleCreationFields
 */
export interface RoleCreationFields {
    /**
     * 
     * @type {string}
     * @memberof RoleCreationFields
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreationFields
     */
    'description': string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof RoleCreationFields
     */
    'permissions': Array<Permission>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScopeType = {
    System: 'system',
    Tenant: 'tenant',
    Cluster: 'cluster',
    Department: 'department',
    Project: 'project'
} as const;

export type ScopeType = typeof ScopeType[keyof typeof ScopeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubjectType = {
    User: 'user',
    App: 'app',
    Group: 'group'
} as const;

export type SubjectType = typeof SubjectType[keyof typeof SubjectType];


/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface UpdateScopeAccessRulesRequest
 */
export interface UpdateScopeAccessRulesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateScopeAccessRulesRequest
     */
    'scopeName': string;
}

/**
 * AccessRulesApi - axios parameter creator
 * @export
 */
export const AccessRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use to retrieve the number of access rules.
         * @summary Count access rules.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAccessRules: async (includeDeleted?: boolean, filterBy?: Array<string>, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/access-rules/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to bind a predefined role to a subject (user, group or application) in a scope.
         * @summary Create an access rule.
         * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRule: async (accessRuleCreationFields: AccessRuleCreationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleCreationFields' is not null or undefined
            assertParamExists('createAccessRule', 'accessRuleCreationFields', accessRuleCreationFields)
            const localVarPath = `/api/v1/authorization/access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleCreationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete the subject permissions assigned by access rule id.
         * @summary Delete an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessRule: async (accessRuleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleId' is not null or undefined
            assertParamExists('deleteAccessRule', 'accessRuleId', accessRuleId)
            const localVarPath = `/api/v1/authorization/access-rules/{accessRuleId}`
                .replace(`{${"accessRuleId"}}`, encodeURIComponent(String(accessRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to retrieve the details of an access rule by id.
         * @summary Get an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRule: async (accessRuleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleId' is not null or undefined
            assertParamExists('getAccessRule', 'accessRuleId', accessRuleId)
            const localVarPath = `/api/v1/authorization/access-rules/{accessRuleId}`
                .replace(`{${"accessRuleId"}}`, encodeURIComponent(String(accessRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of access rules.
         * @summary List the access rules.
         * @param {string} [subjectType] The type of resource we want to filter by.
         * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] The scope resource id that we want to filter by.
         * @param {number} [roleId] The role id we want to filter by.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {AccessRulesSortFilterFields} [sortBy] Sort results by a parameter.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRules: async (subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, sortOrder?: 'asc' | 'desc', sortBy?: AccessRulesSortFilterFields, filterBy?: Array<string>, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (subjectIdFilter !== undefined) {
                localVarQueryParameter['subjectIdFilter'] = subjectIdFilter;
            }

            if (subjectIds) {
                localVarQueryParameter['subjectIds'] = subjectIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = lastUpdated;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to synchronize the statuses of the access rules in the cluster.
         * @summary Sync access rules.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessRules: async (clusterUuid: string, accessRuleForSync?: Array<AccessRuleForSync>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncAccessRules', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/authorization/access-rules/{clusterUuid}/sync`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleForSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the status of the access rule in the cluster by id.
         * @summary Update an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessRuleStatus: async (accessRuleId: number, clusterUuid: string, accessRuleStatusRequest: AccessRuleStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleId' is not null or undefined
            assertParamExists('updateAccessRuleStatus', 'accessRuleId', accessRuleId)
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateAccessRuleStatus', 'clusterUuid', clusterUuid)
            // verify required parameter 'accessRuleStatusRequest' is not null or undefined
            assertParamExists('updateAccessRuleStatus', 'accessRuleStatusRequest', accessRuleStatusRequest)
            const localVarPath = `/api/v1/authorization/access-rules/{accessRuleId}/{clusterUuid}/status`
                .replace(`{${"accessRuleId"}}`, encodeURIComponent(String(accessRuleId)))
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessRulesApi - functional programming interface
 * @export
 */
export const AccessRulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessRulesApiAxiosParamCreator(configuration)
    return {
        /**
         * Use to retrieve the number of access rules.
         * @summary Count access rules.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countAccessRules(includeDeleted?: boolean, filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountAccessRules200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countAccessRules(includeDeleted, filterBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to bind a predefined role to a subject (user, group or application) in a scope.
         * @summary Create an access rule.
         * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessRule(accessRuleCreationFields: AccessRuleCreationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessRule(accessRuleCreationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete the subject permissions assigned by access rule id.
         * @summary Delete an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessRule(accessRuleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccessRule(accessRuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to retrieve the details of an access rule by id.
         * @summary Get an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessRule(accessRuleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRuleById>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessRule(accessRuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of access rules.
         * @summary List the access rules.
         * @param {string} [subjectType] The type of resource we want to filter by.
         * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] The scope resource id that we want to filter by.
         * @param {number} [roleId] The role id we want to filter by.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {AccessRulesSortFilterFields} [sortBy] Sort results by a parameter.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessRules(subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, sortOrder?: 'asc' | 'desc', sortBy?: AccessRulesSortFilterFields, filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccessRules200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessRules(subjectType, subjectIdFilter, subjectIds, limit, offset, lastUpdated, includeDeleted, clusterId, scopeType, scopeId, roleId, sortOrder, sortBy, filterBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to synchronize the statuses of the access rules in the cluster.
         * @summary Sync access rules.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAccessRules(clusterUuid: string, accessRuleForSync?: Array<AccessRuleForSync>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAccessRules(clusterUuid, accessRuleForSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the status of the access rule in the cluster by id.
         * @summary Update an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessRuleStatus(accessRuleId: number, clusterUuid: string, accessRuleStatusRequest: AccessRuleStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessRuleStatus(accessRuleId, clusterUuid, accessRuleStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessRulesApi - factory interface
 * @export
 */
export const AccessRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessRulesApiFp(configuration)
    return {
        /**
         * Use to retrieve the number of access rules.
         * @summary Count access rules.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAccessRules(includeDeleted?: boolean, filterBy?: Array<string>, search?: string, options?: any): AxiosPromise<CountAccessRules200Response> {
            return localVarFp.countAccessRules(includeDeleted, filterBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to bind a predefined role to a subject (user, group or application) in a scope.
         * @summary Create an access rule.
         * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRule(accessRuleCreationFields: AccessRuleCreationFields, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.createAccessRule(accessRuleCreationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete the subject permissions assigned by access rule id.
         * @summary Delete an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessRule(accessRuleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccessRule(accessRuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to retrieve the details of an access rule by id.
         * @summary Get an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRule(accessRuleId: number, options?: any): AxiosPromise<AccessRuleById> {
            return localVarFp.getAccessRule(accessRuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of access rules.
         * @summary List the access rules.
         * @param {string} [subjectType] The type of resource we want to filter by.
         * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] The scope resource id that we want to filter by.
         * @param {number} [roleId] The role id we want to filter by.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {AccessRulesSortFilterFields} [sortBy] Sort results by a parameter.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRules(subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, sortOrder?: 'asc' | 'desc', sortBy?: AccessRulesSortFilterFields, filterBy?: Array<string>, search?: string, options?: any): AxiosPromise<GetAccessRules200Response> {
            return localVarFp.getAccessRules(subjectType, subjectIdFilter, subjectIds, limit, offset, lastUpdated, includeDeleted, clusterId, scopeType, scopeId, roleId, sortOrder, sortBy, filterBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to synchronize the statuses of the access rules in the cluster.
         * @summary Sync access rules.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessRules(clusterUuid: string, accessRuleForSync?: Array<AccessRuleForSync>, options?: any): AxiosPromise<void> {
            return localVarFp.syncAccessRules(clusterUuid, accessRuleForSync, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the status of the access rule in the cluster by id.
         * @summary Update an access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessRuleStatus(accessRuleId: number, clusterUuid: string, accessRuleStatusRequest: AccessRuleStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAccessRuleStatus(accessRuleId, clusterUuid, accessRuleStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessRulesApi - object-oriented interface
 * @export
 * @class AccessRulesApi
 * @extends {BaseAPI}
 */
export class AccessRulesApi extends BaseAPI {
    /**
     * Use to retrieve the number of access rules.
     * @summary Count access rules.
     * @param {boolean} [includeDeleted] True to include deleted objects in the result.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public countAccessRules(includeDeleted?: boolean, filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).countAccessRules(includeDeleted, filterBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to bind a predefined role to a subject (user, group or application) in a scope.
     * @summary Create an access rule.
     * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public createAccessRule(accessRuleCreationFields: AccessRuleCreationFields, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).createAccessRule(accessRuleCreationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete the subject permissions assigned by access rule id.
     * @summary Delete an access rule.
     * @param {number} accessRuleId The id of the access rule to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public deleteAccessRule(accessRuleId: number, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).deleteAccessRule(accessRuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to retrieve the details of an access rule by id.
     * @summary Get an access rule.
     * @param {number} accessRuleId The id of the access rule to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public getAccessRule(accessRuleId: number, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).getAccessRule(accessRuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of access rules.
     * @summary List the access rules.
     * @param {string} [subjectType] The type of resource we want to filter by.
     * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
     * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {string} [lastUpdated] Filter by last update time.
     * @param {boolean} [includeDeleted] True to include deleted objects in the result.
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [scopeType] The type of resource we want to filter by.
     * @param {string} [scopeId] The scope resource id that we want to filter by.
     * @param {number} [roleId] The role id we want to filter by.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {AccessRulesSortFilterFields} [sortBy] Sort results by a parameter.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public getAccessRules(subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, sortOrder?: 'asc' | 'desc', sortBy?: AccessRulesSortFilterFields, filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).getAccessRules(subjectType, subjectIdFilter, subjectIds, limit, offset, lastUpdated, includeDeleted, clusterId, scopeType, scopeId, roleId, sortOrder, sortBy, filterBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to synchronize the statuses of the access rules in the cluster.
     * @summary Sync access rules.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public syncAccessRules(clusterUuid: string, accessRuleForSync?: Array<AccessRuleForSync>, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).syncAccessRules(clusterUuid, accessRuleForSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the status of the access rule in the cluster by id.
     * @summary Update an access rule.
     * @param {number} accessRuleId The id of the access rule to retrieve
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public updateAccessRuleStatus(accessRuleId: number, clusterUuid: string, accessRuleStatusRequest: AccessRuleStatusRequest, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).updateAccessRuleStatus(accessRuleId, clusterUuid, accessRuleStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create internal access rule with out privileged escalation validation.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalAccessRule: async (internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCreateAccessRuleRequest' is not null or undefined
            assertParamExists('createInternalAccessRule', 'internalCreateAccessRuleRequest', internalCreateAccessRuleRequest)
            const localVarPath = `/internal/authorization/access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCreateAccessRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested scope resource
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScopeAccessRules: async (scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, lastUpdated?: string, roles?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopeType' is not null or undefined
            assertParamExists('deleteScopeAccessRules', 'scopeType', scopeType)
            // verify required parameter 'scopeId' is not null or undefined
            assertParamExists('deleteScopeAccessRules', 'scopeId', scopeId)
            const localVarPath = `/internal/authorization/scope-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = lastUpdated;
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested subject.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {string} subjectId The subject id that we want to filter by.
         * @param {boolean} [dryRun] True to perform validations only, without side effects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectAccessRules: async (subjectType: string, subjectId: string, dryRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectType' is not null or undefined
            assertParamExists('deleteSubjectAccessRules', 'subjectType', subjectType)
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('deleteSubjectAccessRules', 'subjectId', subjectId)
            const localVarPath = `/internal/authorization/subject-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (subjectId !== undefined) {
                localVarQueryParameter['subjectId'] = subjectId;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all group names of a tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/authorization/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get access rules that assigned to the requested scope resource
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [roles] The roles we want to filter by.
         * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
         * @param {string} [projectIds] The project scope resource ids that we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeAccessRules: async (clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/authorization/scope-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }

            if (departmentIds !== undefined) {
                localVarQueryParameter['departmentIds'] = departmentIds;
            }

            if (projectIds !== undefined) {
                localVarQueryParameter['projectIds'] = projectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List specific subject access rules.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectAccessRules: async (subjectType: string, subjectIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectType' is not null or undefined
            assertParamExists('getSubjectAccessRules', 'subjectType', subjectType)
            const localVarPath = `/internal/authorization/subject-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (subjectIds) {
                localVarQueryParameter['subjectIds'] = subjectIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update access rules that assigned to the requested scope resource
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScopeAccessRules: async (scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopeType' is not null or undefined
            assertParamExists('updateScopeAccessRules', 'scopeType', scopeType)
            // verify required parameter 'scopeId' is not null or undefined
            assertParamExists('updateScopeAccessRules', 'scopeId', scopeId)
            // verify required parameter 'updateScopeAccessRulesRequest' is not null or undefined
            assertParamExists('updateScopeAccessRules', 'updateScopeAccessRulesRequest', updateScopeAccessRulesRequest)
            const localVarPath = `/internal/authorization/scope-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScopeAccessRulesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create internal access rule with out privileged escalation validation.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalAccessRule(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalAccessRule(internalCreateAccessRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested scope resource
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScopeAccessRules(scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, lastUpdated?: string, roles?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScopeAccessRules(scopeType, scopeId, lastUpdated, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested subject.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {string} subjectId The subject id that we want to filter by.
         * @param {boolean} [dryRun] True to perform validations only, without side effects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubjectAccessRules(subjectType: string, subjectId: string, dryRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubjectAccessRules(subjectType, subjectId, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all group names of a tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get access rules that assigned to the requested scope resource
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [roles] The roles we want to filter by.
         * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
         * @param {string} [projectIds] The project scope resource ids that we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopeAccessRules(clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccessRules200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopeAccessRules(clusterId, roles, departmentIds, projectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List specific subject access rules.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjectAccessRules(subjectType: string, subjectIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessRule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubjectAccessRules(subjectType, subjectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update access rules that assigned to the requested scope resource
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScopeAccessRules(scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScopeAccessRules(scopeType, scopeId, updateScopeAccessRulesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Create internal access rule with out privileged escalation validation.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalAccessRule(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.createInternalAccessRule(internalCreateAccessRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested scope resource
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScopeAccessRules(scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, lastUpdated?: string, roles?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScopeAccessRules(scopeType, scopeId, lastUpdated, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested subject.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {string} subjectId The subject id that we want to filter by.
         * @param {boolean} [dryRun] True to perform validations only, without side effects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectAccessRules(subjectType: string, subjectId: string, dryRun?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSubjectAccessRules(subjectType, subjectId, dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all group names of a tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(options?: any): AxiosPromise<GetGroups200Response> {
            return localVarFp.getGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get access rules that assigned to the requested scope resource
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [roles] The roles we want to filter by.
         * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
         * @param {string} [projectIds] The project scope resource ids that we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeAccessRules(clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options?: any): AxiosPromise<GetAccessRules200Response> {
            return localVarFp.getScopeAccessRules(clusterId, roles, departmentIds, projectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List specific subject access rules.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectAccessRules(subjectType: string, subjectIds?: Array<string>, options?: any): AxiosPromise<Array<AccessRule>> {
            return localVarFp.getSubjectAccessRules(subjectType, subjectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update access rules that assigned to the requested scope resource
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScopeAccessRules(scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateScopeAccessRules(scopeType, scopeId, updateScopeAccessRulesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary Create internal access rule with out privileged escalation validation.
     * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public createInternalAccessRule(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).createInternalAccessRule(internalCreateAccessRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete access rules that assigned to the requested scope resource
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
     * @param {string} scopeId The scope resource id that we want to filter by.
     * @param {string} [lastUpdated] Filter by last update time.
     * @param {string} [roles] The roles we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteScopeAccessRules(scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, lastUpdated?: string, roles?: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deleteScopeAccessRules(scopeType, scopeId, lastUpdated, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete access rules that assigned to the requested subject.
     * @param {string} subjectType The type of resource we want to filter by.
     * @param {string} subjectId The subject id that we want to filter by.
     * @param {boolean} [dryRun] True to perform validations only, without side effects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteSubjectAccessRules(subjectType: string, subjectId: string, dryRun?: boolean, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deleteSubjectAccessRules(subjectType, subjectId, dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all group names of a tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getGroups(options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get access rules that assigned to the requested scope resource
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [roles] The roles we want to filter by.
     * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
     * @param {string} [projectIds] The project scope resource ids that we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getScopeAccessRules(clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getScopeAccessRules(clusterId, roles, departmentIds, projectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List specific subject access rules.
     * @param {string} subjectType The type of resource we want to filter by.
     * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getSubjectAccessRules(subjectType: string, subjectIds?: Array<string>, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getSubjectAccessRules(subjectType, subjectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update access rules that assigned to the requested scope resource
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
     * @param {string} scopeId The scope resource id that we want to filter by.
     * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateScopeAccessRules(scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateScopeAccessRules(scopeType, scopeId, updateScopeAccessRulesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
         * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluatePermission: async (backendEvaluationRequest: BackendEvaluationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backendEvaluationRequest' is not null or undefined
            assertParamExists('evaluatePermission', 'backendEvaluationRequest', backendEvaluationRequest)
            const localVarPath = `/api/v1/authorization/evaluate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backendEvaluationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a summary of user permissions.
         * @summary Get a summary of user permissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to calculate user permitted scopes for an action on a resource.
         * @summary Calculate permitted scopes.
         * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermittedScopes: async (getPermittedScopesRequest: GetPermittedScopesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPermittedScopesRequest' is not null or undefined
            assertParamExists('getPermittedScopes', 'getPermittedScopesRequest', getPermittedScopesRequest)
            const localVarPath = `/api/v1/authorization/permitted-scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPermittedScopesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
         * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evaluatePermission(backendEvaluationRequest: BackendEvaluationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermittedScopes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evaluatePermission(backendEvaluationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a summary of user permissions.
         * @summary Get a summary of user permissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Permission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to calculate user permitted scopes for an action on a resource.
         * @summary Calculate permitted scopes.
         * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermittedScopes(getPermittedScopesRequest: GetPermittedScopesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermittedScopesActions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermittedScopes(getPermittedScopesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
         * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluatePermission(backendEvaluationRequest: BackendEvaluationRequest, options?: any): AxiosPromise<PermittedScopes> {
            return localVarFp.evaluatePermission(backendEvaluationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a summary of user permissions.
         * @summary Get a summary of user permissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(options?: any): AxiosPromise<Array<Permission>> {
            return localVarFp.getPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * Use to calculate user permitted scopes for an action on a resource.
         * @summary Calculate permitted scopes.
         * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermittedScopes(getPermittedScopesRequest: GetPermittedScopesRequest, options?: any): AxiosPromise<PermittedScopesActions> {
            return localVarFp.getPermittedScopes(getPermittedScopesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
     * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public evaluatePermission(backendEvaluationRequest: BackendEvaluationRequest, options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).evaluatePermission(backendEvaluationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a summary of user permissions.
     * @summary Get a summary of user permissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermissions(options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to calculate user permitted scopes for an action on a resource.
     * @summary Calculate permitted scopes.
     * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermittedScopes(getPermittedScopesRequest: GetPermittedScopesRequest, options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getPermittedScopes(getPermittedScopesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use to create a role.
         * @summary Create a role.
         * @param {RoleCreationFields} roleCreationFields The role to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreationFields: RoleCreationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleCreationFields' is not null or undefined
            assertParamExists('createRole', 'roleCreationFields', roleCreationFields)
            const localVarPath = `/api/v1/authorization/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete a role by id.
         * @summary Delete a role by id.
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (roleIdPath: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdPath' is not null or undefined
            assertParamExists('deleteRole', 'roleIdPath', roleIdPath)
            const localVarPath = `/api/v1/authorization/roles/{roleIdPath}`
                .replace(`{${"roleIdPath"}}`, encodeURIComponent(String(roleIdPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of a role by id.
         * @summary Get a role by id.
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (roleIdPath: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdPath' is not null or undefined
            assertParamExists('getRole', 'roleIdPath', roleIdPath)
            const localVarPath = `/api/v1/authorization/roles/{roleIdPath}`
                .replace(`{${"roleIdPath"}}`, encodeURIComponent(String(roleIdPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to retrieve a list of roles.
         * @summary Get a list of roles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Use to create a role.
         * @summary Create a role.
         * @param {RoleCreationFields} roleCreationFields The role to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreationFields: RoleCreationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(roleCreationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete a role by id.
         * @summary Delete a role by id.
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(roleIdPath: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(roleIdPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the details of a role by id.
         * @summary Get a role by id.
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(roleIdPath: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(roleIdPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to retrieve a list of roles.
         * @summary Get a list of roles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * Use to create a role.
         * @summary Create a role.
         * @param {RoleCreationFields} roleCreationFields The role to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreationFields: RoleCreationFields, options?: any): AxiosPromise<Role> {
            return localVarFp.createRole(roleCreationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete a role by id.
         * @summary Delete a role by id.
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(roleIdPath: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(roleIdPath, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of a role by id.
         * @summary Get a role by id.
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(roleIdPath: number, options?: any): AxiosPromise<Role> {
            return localVarFp.getRole(roleIdPath, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to retrieve a list of roles.
         * @summary Get a list of roles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * Use to create a role.
     * @summary Create a role.
     * @param {RoleCreationFields} roleCreationFields The role to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRole(roleCreationFields: RoleCreationFields, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRole(roleCreationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete a role by id.
     * @summary Delete a role by id.
     * @param {number} roleIdPath The id of the role to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRole(roleIdPath: number, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).deleteRole(roleIdPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of a role by id.
     * @summary Get a role by id.
     * @param {number} roleIdPath The id of the role to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRole(roleIdPath: number, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRole(roleIdPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to retrieve a list of roles.
     * @summary Get a list of roles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }
}


