<template>
  <section class="q-pt-md row justify-start">
    <q-field
      class="col-11 scope-input"
      color="grey-8"
      label="Scope"
      :clearable="clearable"
      @clear="$emit('clear')"
      clear-icon="fa-regular fa-xmark"
      :model-value="selectedScopePath"
      :rules="[requiredScope]"
      no-error-icon
      ref="scope-input"
      @focus="openTree"
      :readonly="readonly"
    >
      {{ selectedScopePath }}
    </q-field>
    <div class="tree-button-wrapper">
      <q-btn class="tree-button" color="primary" icon="fa-solid fa-folder-tree" size="sm" ref="tree-btn">
        <runai-org-tree-with-pop-over
          :readonly="readonly"
          :tick-strategy="tickStrategy"
          :allowed-scopes="allowedScopes"
          :hidden-scope-types="hiddenScopeTypes"
          :selected="selectedScope || undefined"
          :placeholder-search="placeholderSearch"
          @update:selected="$emit('update-selected', $event)"
          :title="title"
          cover
        />
      </q-btn>
    </div>
  </section>
  <section class="q-pt-md row justify-start">
    <div class="col-12 q-mr-xl scope-input error">
      <p v-if="error">
        You don't have permissions to set this scope for the role selected above. Try selecting a different role and
        scope combination.
      </p>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiOrgTreeWithPopOver } from "@/components/common/runai-org-tree/with-popover";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import type { ISelectOption } from "@/models/global.model";
import { errorMessages } from "@/common/error-message.constant";
import type { QBtn } from "quasar";
import type { ScopeType } from "@/swagger-models/authorization-client";
import { type PermittedScopes } from "@/swagger-models/authorization-client";

export default defineComponent({
  name: "scope-input",
  components: { RunaiOrgTreeWithPopOver },
  emits: ["clear", "update-selected"],
  props: {
    selectedScope: {
      type: [Object, null] as PropType<null | IOrgTreeNodeId>,
      required: true,
    },
    error: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    allowedScopes: {
      type: Object as PropType<PermittedScopes>,
      required: false,
    },
    hiddenScopeTypes: {
      type: Object as PropType<Record<ScopeType, boolean>>,
      default: () => ({}),
    },
    tickStrategy: {
      type: String as PropType<"leaf" | "leaf-filtered" | "none" | "strict" | undefined>,
      default: "strict",
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    clearable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    placeholderSearch: {
      type: String as PropType<string>,
      default: `Search for a cluster, department, or project`,
    },
    title: {
      type: String as PropType<string>,
      default: "Scope",
    },
  },
  computed: {
    selectedScopePath() {
      return this.selectedScope?.path || "";
    },
  },
  methods: {
    requiredScope(val: ISelectOption): boolean | string {
      return !!val || errorMessages.SCOPE_TYPE_NOT_EMPTY;
    },
    openTree(): void {
      if (this.readonly) {
        return;
      }
      (this.$refs["tree-btn"] as QBtn).$el.click();
    },
  },
});
</script>

<style scoped lang="scss">
.tree-button-wrapper {
  align-self: center;
  margin-left: 10px;
}
.tree-button {
  width: 40px;
  height: 40px;
}
.x-button {
  margin-top: 10px;
  color: $black-54;
  width: 40px;
  height: 40px;
}
</style>
