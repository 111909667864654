<template>
  <p>Enter the application name</p>
  <runai-name-validation
    :model-value="modelValue"
    :rules="[isEmpty, isValidFormat, isNameNotTaken]"
    debounce="300"
    auto-focus
    @update:model-value="$emit('update:model-value', $event)"
  ></runai-name-validation>
</template>
<script lang="ts">
import { errorMessages } from "@/common/error-message.constant";
import { isFirstLetterIsLowerCase, isLowerCaseAndNumbersAndUnderscore, isNotEmpty } from "@/common/form.validators";
import { RunaiNameValidation } from "@/components/common/runai-name-validation";
import type { PropType } from "vue";
import { applicationsService } from "@/services/control-plane/applications.service/applications.service";
import type { IApplication } from "@/models/applications.model";
import { personalApplicationsService } from "@/services/control-plane/personal-applications.service/personal-applications.service";

export default {
  components: { RunaiNameValidation },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    isPersonalApp: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      apps: [] as IApplication[],
    };
  },
  methods: {
    async isNameNotTaken(name: string): Promise<boolean | string> {
      if (this.apps.length === 0) {
        try {
          if (this.isPersonalApp) {
            this.apps = (await personalApplicationsService.getPersonalApps()) as IApplication[];
          } else {
            this.apps = await applicationsService.list({});
          }
        } catch (error) {
          console.warn(`Failed to fetch applications list, cannot verify if app ${name} exist`, error);
          return true;
        }
      }
      return !this.apps.find((app) => app.name === name) || errorMessages.NAME_ALREADY_EXIST;
    },
    isEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.NAME_NOT_EMPTY;
    },
    isValidFormat(val: string): boolean | string {
      const isAppValid = isLowerCaseAndNumbersAndUnderscore(val) && isFirstLetterIsLowerCase(val);
      return isAppValid || errorMessages.VALID_APP_FORMAT;
    },
  },
};
</script>
<style lang=""></style>
