// models
import type { ITableColumn } from "@/models/table.model";
import { getField, getFieldContent } from "@/utils/table-format.util";
import { exportFile } from "quasar";
import { alertUtil } from "@/utils/alert.util";
import { Notify } from "quasar";
import type { IStatusColOptions } from "@/models/table.model";

export const tableUtil = {
  getDisplayColumns,
  customColumnSort,
  exportTableAsCsv,
  getStatusColOptions,
  ratioSort,
};

function getDisplayColumns(columns: Array<ITableColumn>, displayedColumns: Array<string> = []): Array<ITableColumn> {
  const displayedColumnsMap: Set<string> = new Set(displayedColumns);
  return columns.filter((col: ITableColumn) => displayedColumnsMap.has(col.name));
}

function customColumnSort<T>(
  column: ITableColumn,
  rowA: T,
  rowB: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertFunc: ((val: any) => any) | null = null,
): number {
  let aValue = getFieldContent(column, rowA);
  let bValue = getFieldContent(column, rowB);

  if (convertFunc) {
    aValue = convertFunc(aValue);
    bValue = convertFunc(bValue);
  }

  const isPercentage = (value: string): boolean => /^(\d+(\.\d+)?|\.\d+)%$/.test(value);

  const sortPercentages = (valueA: string, valueB: string): number => parseFloat(valueA) - parseFloat(valueB);

  switch (typeof aValue) {
    case "number":
      return aValue - bValue;

    case "string":
      if (isPercentage(aValue) && isPercentage(bValue)) {
        return sortPercentages(aValue, bValue);
      }
      return aValue.localeCompare(bValue);
    case "object":
      if (Array.isArray(aValue) && Array.isArray(bValue)) {
        return aValue.length - bValue.length;
      }
      return 0;
    default:
      return 0;
  }
}
//use when need to sort 'x/y' for e.g workload table runningPodsVsRequestedPods
function ratioSort(x1: number, y1: number, x2: number, y2: number): number {
  const number1 = x1 * 10 + y1;
  const number2 = x2 * 10 + y2;
  return number1 - number2;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function exportTableAsCsv(tableName: string, rows: any[], columns: ITableColumn[]): void {
  try {
    const content = [columns.map((col: ITableColumn) => _wrapCsvValue(col.label))]
      .concat(
        rows.map((row) =>
          columns
            .map((col) => {
              if (col.exportFormat) {
                return _wrapCsvValue(col.exportFormat(row));
              } else {
                return _wrapCsvValue(getField(col, row), col.format, row);
              }
            })
            .join(","),
        ),
      )
      .join("\r\n");

    const status = exportFile(`${tableName}.csv`, content, "text/csv");
    if (status !== true) {
      Notify.create(alertUtil.getError(status.message));
    } else {
      Notify.create(alertUtil.getSuccess("File exported successfully"));
    }
  } catch (e) {
    console.error(e);
    Notify.create(alertUtil.getError("Failed to export file"));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _wrapCsvValue(val: any, formatFn?: (val: any, row: any) => any, row?: any) {
  let formatted = formatFn !== void 0 ? formatFn(val, row) : val;

  formatted = formatted === void 0 || formatted === null ? "" : String(formatted);

  formatted = formatted.split('"').join('""');

  return `"${formatted}"`;
}

function getStatusColOptions(statusOptions: IStatusColOptions, tooltipText?: string): IStatusColOptions {
  if (statusOptions) {
    return {
      status: statusOptions.status,
      tooltipText: tooltipText || statusOptions.tooltipText || "",
      color: statusOptions.color,
      displayAnimation: statusOptions.displayAnimation,
      filterKey: statusOptions.filterKey,
      statusUpdatedTimeInMs: statusOptions.statusUpdatedTimeInMs,
      showIconToolTip: statusOptions.showIconToolTip || false,
    };
  }
  return {
    status: "-",
    tooltipText: "",
    displayAnimation: false,
    filterKey: "status",
  };
}
