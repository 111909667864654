import type { TelemetryResponseValuesInner } from "@/swagger-models/workloads-client";
import { useAuthStore } from "@/stores/auth.store";
import type { QNotifyCreateOptions } from "quasar";
import { alertUtil } from "@/utils/alert.util";

export const dashboardUtil = { calculateTelemetrySum, getCsvErrorMessage };

function calculateTelemetrySum(values: TelemetryResponseValuesInner[]): number {
  return values.reduce((totalSum, item) => parseFloat(item?.value || "0") + totalSum, 0);
}

function getCsvErrorMessage(): QNotifyCreateOptions {
  const email = useAuthStore().currentUser.username;
  const message = `CSV couldn't be downloaded. Try again. If the issue persists, <a href="https://runai.my.salesforce-sites.com/casesupport/CreateCaseForm?email=${email}" target="_blank" style="color: white; text-decoration: underline">contact Run:ai support.</a>`;
  return alertUtil.getSuccess(message, { html: true });
}
