<template>
  <highcharts :options="bubbleChartOptions" ref="chart" key="bubble-chart" />
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { Chart as Highcharts } from "highcharts-vue";
//highcharts
import type { Options as HighchartsOptions, SeriesPackedbubbleOptions } from "highcharts";
//util
import { chartUtil } from "@/utils/chart.util";
//model
import type {
  TelemetryResponseValuesInner,
  TelemetryResponseValuesInnerGroupsInner,
} from "@/swagger-models/cluster-service-client";
import { ENodeTelemetryGroupBy } from "@/models/cluster.model";
import type { CHART_COLOR_PALETTE } from "@/models/chart.model";
import { chartColorPaletteArray } from "@/models/chart.model";

interface IBubbleChartOptions extends HighchartsOptions {
  series: SeriesPackedbubbleOptions[];
}

interface IBubbleChartSeriesOptionsData {
  name: string;
  value: number;
  color: string;
  meta: {
    totalGpusDevices: number;
  };
}
interface IBubbleChartSeriesOptions extends SeriesPackedbubbleOptions {
  name: string;
  color: string;
  data: IBubbleChartSeriesOptionsData[];
}
export default defineComponent({
  name: "node-free-gpu-chart",
  components: { Highcharts },
  props: {
    nodePoolName: {
      type: String as PropType<string>,
      default: "",
    },
    nodePoolColor: {
      type: [String, null] as PropType<CHART_COLOR_PALETTE | null>,
      default: "",
    },
    nodePoolFreeGpusTelemetry: {
      type: Array as PropType<TelemetryResponseValuesInner[]>,
      default: () => [],
    },
    nodeTotalGpuDevicesTelemetry: {
      type: Array as PropType<TelemetryResponseValuesInner[]>,
      default: () => [],
    },
  },
  data() {
    return {
      bubbleChartOptions: {
        series: [] as IBubbleChartSeriesOptions[],
      } as IBubbleChartOptions,
    };
  },
  created() {
    this.loadBubbleChartOptions();
    this.loadBubbleChartData(this.nodePoolFreeGpusTelemetry, this.nodePoolName, this.nodePoolColor);
  },
  methods: {
    loadBubbleChartOptions(): void {
      this.bubbleChartOptions = {
        ...chartUtil.getBasicWidgetChartOptions({
          yAxisTitle: "Free resources by node pool",
          type: "packedbubble",
          height: 240,
        }),
        plotOptions: {
          packedbubble: {
            minSize: "30%",
            maxSize: "80%",
          },
        },
        series: [],
      };
      if (this.bubbleChartOptions.tooltip) {
        this.bubbleChartOptions.tooltip.pointFormatter = function (this: Highcharts.Point) {
          //@ts-ignore
          const totalGpusDevices = this.meta.totalGpusDevices || 0;
          const freeGpus = this.y || 0;

          const nodeTooltip = chartUtil.formatTooltipValue("Node", this?.color, this.name, "");
          const totalGpusTooltip = chartUtil.formatTooltipValue("Total GPU devices", "", totalGpusDevices, "");
          const freeGpusTooltip = chartUtil.formatTooltipValue("Free GPU devices  ", "", freeGpus, "");

          return `${nodeTooltip}${totalGpusTooltip}${freeGpusTooltip}`;
        };
        this.bubbleChartOptions.tooltip.positioner = function (labelWidth, labelHeight, point) {
          return {
            x: point.plotX + 80,
            y: point.plotY - 30,
          };
        };
      }
    },
    loadBubbleChartData(
      telemetryData: TelemetryResponseValuesInner[],
      nodePoolName: string,
      nodePoolColor: CHART_COLOR_PALETTE | null,
    ): void {
      const filteredData = this.filterTelemetryData(telemetryData, nodePoolName);
      const bubbleChartOptions = this.createBubbleChartOptions(nodePoolName, nodePoolColor, filteredData);
      this.bubbleChartOptions.series = [bubbleChartOptions];
    },
    extractNodePoolGroupFromTelemetry(
      telemetryInner: TelemetryResponseValuesInner,
      nodePoolName: string,
    ): TelemetryResponseValuesInnerGroupsInner | undefined {
      return telemetryInner?.groups?.find(
        (groupInner: TelemetryResponseValuesInnerGroupsInner) =>
          groupInner.key === ENodeTelemetryGroupBy.Nodepool && groupInner.value === nodePoolName,
      );
    },
    filterTelemetryData(telemetryData: TelemetryResponseValuesInner[], nodePoolName: string) {
      const telemetryDataMap = new Map<string, IBubbleChartSeriesOptionsData>();
      const paletteWithoutCurrentNodePoolColor: CHART_COLOR_PALETTE[] = chartColorPaletteArray.filter(
        (color: CHART_COLOR_PALETTE) => color !== this.nodePoolColor,
      );
      telemetryData.forEach((telemetryInner) => {
        const group = this.extractNodePoolGroupFromTelemetry(telemetryInner, nodePoolName);

        if (group) {
          const nodeName = this.getNodeName(telemetryInner);
          const totalGpusDevices = this.getTotalGpusDevicesByNodeName(nodeName || "");
          if (nodeName) {
            telemetryDataMap.set(nodeName, {
              name: nodeName || "",
              value: parseFloat(telemetryInner.value),
              color: paletteWithoutCurrentNodePoolColor[telemetryDataMap.size],
              meta: {
                totalGpusDevices,
              },
            });
          }
        }
      });

      return Array.from(telemetryDataMap.values());
    },
    createBubbleChartOptions(
      nodePoolName: string,
      nodePoolColor: CHART_COLOR_PALETTE | null,
      bubbleChartData: IBubbleChartSeriesOptionsData[],
    ): IBubbleChartSeriesOptions {
      return {
        name: nodePoolName,
        color: nodePoolColor === null ? "" : nodePoolColor,
        data: bubbleChartData,
        type: "packedbubble",
      };
    },
    getNodeName(telemetryValue: TelemetryResponseValuesInner): string | undefined {
      return telemetryValue?.groups?.find(
        (group: TelemetryResponseValuesInnerGroupsInner) => group.key === ENodeTelemetryGroupBy.Node,
      )?.value;
    },
    getTotalGpusDevicesByNodeName(nodeName: string): number {
      const node = this.nodeTotalGpuDevicesTelemetry.find((node: TelemetryResponseValuesInner) =>
        node.groups?.find(
          (group: TelemetryResponseValuesInnerGroupsInner) =>
            group.key === ENodeTelemetryGroupBy.Node && group.value === nodeName,
        ),
      );
      return node ? parseFloat(node.value) : 0;
    },
  },
});
</script>

<style scoped lang="scss"></style>
