import { httpService } from "@/services/infra/https.service/http.service";
import { identityManagerApi } from "@/services/infra/client-apis/identity-manager-api/identity-manager-api";
import type {
  PersonalApp,
  PersonalAppCreationRequest,
  PersonalAppPostResponse,
  RegeneratePersonalApplicationSecret200Response,
} from "@/swagger-models/identity-manager-client";

export const personalApplicationsService = {
  getPersonalApps,
  getPersonalApp,
  createPersonalApp,
  removePersonalApp,
  generatePersonalAppSecret,
};

async function getPersonalApps(): Promise<PersonalApp[]> {
  try {
    const response = await identityManagerApi.personalApplicationsApi.getPersonalApplications();
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getPersonalApp(appId: string): Promise<PersonalApp> {
  try {
    const response = await identityManagerApi.personalApplicationsApi.getPersonalApplicationById(appId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createPersonalApp(application: PersonalAppCreationRequest): Promise<PersonalAppPostResponse> {
  try {
    const response = await identityManagerApi.personalApplicationsApi.createPersonalApplication(application);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removePersonalApp(appId: string): Promise<void> {
  try {
    await identityManagerApi.personalApplicationsApi.deletePersonalApplicationById(appId);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function generatePersonalAppSecret(appId: string): Promise<RegeneratePersonalApplicationSecret200Response> {
  try {
    const response = await identityManagerApi.personalApplicationsApi.regeneratePersonalApplicationSecret(appId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
