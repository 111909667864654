<template>
  <runai-tooltip-wrapper
    anchor="bottom middle"
    self="top middle"
    :display-tooltip="isDisabled"
    :tooltip-text="disabledTooltip"
  >
    <q-btn
      class="runai-dropdown btn-icon-sm"
      :label="label"
      :icon="icon"
      :flat="flat"
      color="primary"
      :disable="isDisabled"
    >
      <div class="btn-container row items-center">
        <slot name="btn"></slot>
      </div>
      <q-menu fit :auto-close="autoClose">
        <q-list>
          <q-item clickable v-for="opt in options" :key="opt[rowKey]" @click="$emit('on-selected', opt)">
            <q-item-section class="runai-dropdown-option">
              <slot v-if="$slots.option" name="option" v-bind="opt"></slot>
              <div v-else>{{ opt.label }}</div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </runai-tooltip-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import { RunaiTooltipWrapper } from "../runai-tooltip-wrapper";

export default defineComponent({
  components: { RunaiTooltipWrapper },
  emits: ["on-selected"],
  props: {
    options: {
      type: Array as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    rowKey: {
      type: String as PropType<string>,
      required: false,
      default: "id",
    },
    autoClose: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disabledTooltip: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    flat: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    icon: {
      type: String as PropType<string>,
      required: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
  },
});
</script>

<style lang="scss">
.runai-dropdown {
  .option {
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
