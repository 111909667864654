<template>
  <section class="personal-app-box q-pa-md row items-center">
    <q-input
      aid="personal-app-name"
      class="col-3 q-mr-md"
      label="Application name"
      stack-label
      :model-value="personalApp.name"
      no-error-icon
      :disable="true"
    />
    <q-input
      aid="personal-app-name"
      class="col-3 q-mr-md"
      label="Creation time"
      stack-label
      :model-value="createdAt"
      no-error-icon
      :disable="true"
    />
    <q-input
      aid="personal-app-last-login"
      class="col-3"
      stack-label
      label="Last login"
      :model-value="lastLogin"
      no-error-icon
      :disable="true"
    />
    <q-space />
    <q-btn
      aid="remove-personal-app-button"
      class="close-button"
      icon="fa-solid fa-trash-can"
      flat
      round
      @click="removePersonalApp"
      ><q-tooltip>Delete application</q-tooltip></q-btn
    >
    <q-btn
      aid="regenerate-password-personal-app-button"
      class="close-button"
      icon="fa-solid fa-lock-hashtag"
      flat
      round
      @click="regeneratePassword"
      ><q-tooltip>Regenerate key</q-tooltip></q-btn
    >
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
// Models
// Utils
import type { PersonalApp } from "@/swagger-models/identity-manager-client";
import { dateUtil } from "@/utils/date.util";

export default defineComponent({
  name: "personal-app-box",
  emits: ["remove-personal-app", "regenerate-password"],
  props: {
    personalApp: {
      type: Object as PropType<PersonalApp>,
      required: true,
    },
  },
  computed: {
    createdAt(): string {
      return this.personalApp.createdAt ? dateUtil.dateAndTimeFormat(new Date(this.personalApp.createdAt)) : "";
    },
    lastLogin(): string {
      return this.personalApp.lastLogin
        ? dateUtil.dateAndTimeFormat(new Date(this.personalApp.lastLogin as string))
        : "Never";
    },
  },
  methods: {
    removePersonalApp(): void {
      this.$emit("remove-personal-app", this.personalApp);
    },
    regeneratePassword(): void {
      this.$emit("regenerate-password", this.personalApp);
    },
  },
});
</script>

<style lang="scss" scoped>
.personal-app-box {
  background-color: $body-background-color;

  .close-button {
    color: $black-54;
    font-size: 12px;
  }
}
</style>
